import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getUID } from '../../../../Helper';
import { ChevronDown } from 'lucide-react';

// Keep your existing styled components as they are
const DropdownContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  flex:1;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:6px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
`;

const DropdownPanel = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  margin-top: 4px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
  padding:24px 12px;
`;

const SearchContainer = styled.div`
  position: relative;
  padding:0px;
  border-radius: 8px;
  border: 1px solid rgb(58 65 66);
  margin-top:12px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 12px 12px 40px;
  background: transparent;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  &::placeholder {
    color: #fff;
  }
`;

const Input = styled.input`
  flex:1;
  padding:6px 10px;
  background: #292D2E;
  border: 1px solid rgb(58 65 66);
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  &::placeholder {
    color: #fff;
  }
`;

const DepositButton = styled.div`
  margin-top: auto;
  flex:1;
  padding:7px 9px;
  display:flex;
  justify-content:center;
  align-items:center;
  background: linear-gradient(to right, #9FE871, #24EE89);
  color: white !important;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  
  &:hover {
    opacity: 0.9;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 14px;
  top: 49%;
  transform: translateY(-50%);
  color: #fff;
`;

const CoinOption = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  cursor: pointer;
  border-radius:12px;
  &:hover {
    background: #323738;
  }
  ${props => props.isSelected && `
    background: #323738;
  `}
`;

const CoinName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Container = styled.div`
  color: #ffffff;
`;

const Container1 = styled.div`
  color: white;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
`;

const HeaderButtonGroup = styled.div`
  display: flex;
  background-color: #292C32;
  border-radius: 50px;
  margin-bottom: 16px;
  width: 50%;
`;

const HeaderButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  border-radius: 50px;
  transition: all 0.3s ease;
  text-align: center;
  ${(props) =>
    props.active
      ? `
        background-color: #3B5432;
        color: white;
      `
      : `
        background-color: transparent;
        color: #9ca3af;
        &:hover {
          background-color: #3B5432;
          color: white;
        }
      `}
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  color: #9ca3af;
  font-size: 14px;
  margin-bottom: 16px;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  font-size: 14px;
  color: white;
  margin-bottom: 12px;
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
`;


const PlaceholderText = styled.div`
  color: #fff;
  font-size:18px;
`;

const SkylaTransaction = () => {
  const [activeTab, setActiveTab] = useState('Deposit');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transactionData, setTransactionData] = useState([]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setOpenDropDown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchData = useCallback(async (tab) => {
    setLoading(true);
    setError(null);

    try {
      const uid = getUID();
      const response = await axios.get(
        `https://api.99exchange.red/api/${tab.toLowerCase()}New`,
        { params: { uid } }
      );
      setTransactionData(response.data);
    } catch (err) {
      console.error('API call failed', err);
      setError('Failed to fetch data.');
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab, fetchData]);

  return (
    <Container>
      <Container1>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <DropdownContainer className="dropdown-container">
            <DropdownButton onClick={(e) => {
              e.stopPropagation();
              setOpenDropDown(!openDropDown);
            }}>
              <CoinName>{activeTab}</CoinName>
              <ChevronDown size={20} />
            </DropdownButton>

            {openDropDown && (
              <DropdownPanel onClick={e => e.stopPropagation()}>
                {["Deposit", "Withdraw"].map((type) => (
                  <CoinOption
                    key={type}
                    onClick={() => {
                      setActiveTab(type);
                      setOpenDropDown(false);
                    }}
                    isSelected={activeTab === type}
                  >
                    <CoinName>{type}</CoinName>
                  </CoinOption>
                ))}
              </DropdownPanel>
            )}
          </DropdownContainer>
        </div>

        {loading ? (
          <EmptyState>
            <PlaceholderText>Loading data...</PlaceholderText>
          </EmptyState>
        ) : error ? (
          <EmptyState>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              width: '100%',
            }}
          >
            <img
              src="https://hash.game/assets/common/empty.png"
              alt="No transactions"
              style={{ height: '400px', width: '200px', objectFit: 'contain' }}
            />
            <PlaceholderText>Oops! There is no data yet!</PlaceholderText>
          </div>
        </EmptyState>
        ) : transactionData.length === 0 ? (
          <EmptyState>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              width: '100%',
            }}>
              <img
                src="https://hash.game/assets/common/empty.png"
                alt="No transactions"
                style={{ height: '400px', width: '200px', objectFit: 'contain' }}
              />
              <PlaceholderText>Oops! There is no data yet!</PlaceholderText>
            </div>
          </EmptyState>
        ) : (
          <div style={{ height: '300px' }}>
            <TableHeader>
              <div>Date</div>
              <div>Amount</div>
              <div>Status</div>
              <div>{activeTab === 'Deposit' ? 'Transaction' : 'Address'}</div>
            </TableHeader>

            {transactionData.map((transaction, index) => (
              <TableRow key={index}>
                <div>
                  {activeTab === 'Deposit'
                    ? formatDateTime(transaction.created_at)
                    : formatDateTime(transaction.date)}
                </div>
                <div>{transaction.amount}</div>
                <div>{transaction.status}</div>
                <div>
                  {activeTab === 'Deposit'
                    ? transaction.orderid
                    : transaction.wallet}
                </div>
              </TableRow>
            ))}
          </div>
        )}
      </Container1>
    </Container>
  );
};

export default SkylaTransaction;