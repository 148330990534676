import React from "react";
import styled from "styled-components";

const DataTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Montserrat, sans-serif;
  border: 1px solid #2c3137;
  border-radius: 10px;

  th {
    padding: 10px 8px;
    font-weight: bold;
    border-bottom: 1px solid #2c3137;
    color: #ffffff90;
  }

  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #2c3137;
  }

  tr:nth-child(even) {
    background-color: #2c3137;
  }

  tbody {
    tr:hover {
      background-color: #2c3137;
    }
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  text-align: center;

  img {
    border-radius: 10px;
    filter: grayscale(1) brightness(0.6);
  }

  div {
    margin-top: 20px;
    opacity: 0.6;
  }
`;

class ReferralHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReferralHistory: true,
      referrals: [
        {
          username: "JohnDoe",
          date: "2024-11-01",
          code: "REF12345",
          vipLevel: "Gold",
          earnedAmount: 150.0,
        },
        {
          username: "JaneSmith",
          date: "2024-11-05",
          code: "REF67890",
          vipLevel: "Silver",
          earnedAmount: 75.5,
        },
      ],
    };
  }

  render() {
    const { isReferralHistory, referrals } = this.state;

    return (
      <>
        {isReferralHistory && referrals.length > 0 ? (
          <DataTable>
            <thead>
              <tr>
                <th>Username</th>
                <th>Date of Registration</th>
                <th>Referral Code</th>
                <th>VIP Level</th>
                <th style={{ textAlign: "right" }}>Earned Amount</th>
              </tr>
            </thead>
            <tbody>
              {referrals.map((referral, index) => (
                <tr key={index}>
                  <td>{referral.username}</td>
                  <td>{referral.date}</td>
                  <td>{referral.code}</td>
                  <td>{referral.vipLevel}</td>
                  <td style={{ textAlign: "right" }}>
                    ${referral.earnedAmount.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </DataTable>
        ) : (
          <EmptyState>
            <img
              src="https://hash.game/assets/common/empty.png"
              alt="crying-lion"
              width={200}
              height={200}
            />
            <div>
              No rewards yet. <br /> Invite friends to join you now!
            </div>
          </EmptyState>
        )}
      </>
    );
  }
}

export default ReferralHistory;
