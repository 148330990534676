import React, { useState } from 'react';
import styled from 'styled-components';

const mockBonusData = [
  { id: 1, type: "Quest Bonus", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-quest-5a485063.png" },
  { id: 2, type: "Lucky Spin", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-lucky-spin-cc90b095.png" },
  { id: 3, type: "Roll Competition", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-roll-a05d018c.png" },
  { id: 5, type: "Weekly Bonus", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-weekly-fc8e3574.png" },
  { id: 6, type: "Monthly Bonus", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-monthly-5aefcffd.png" },
  { id: 10, type: "Deposit Bonus", claimed: 0.00, icon: "https://hash.game/modules/bonus2/assets/icon-deposit-67dfc30e.png" }
];

const mockTransactions = [
  { id: 1, date: "2024-03-19", type: "Quest Bonus", amount: 50.00, status: "Completed" },
  { id: 2, date: "2024-03-18", type: "Lucky Spin", amount: 25.00, status: "Pending" },
  { id: 3, date: "2024-03-17", type: "Roll Competition", amount: 75.00, status: "Completed" },
];

const Container = styled.div`
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  padding: 24px;
  
  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 0;
  }
`;

const Title = styled.h2`
  color: white;
  margin: 0;
  font-size: 24px;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  font-size: 24px;
  padding: 8px;
  
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 6px;
  }
  
  &:hover {
    color: white;
  }
`;

const TabContainer = styled.div`
  background-color: #24272c;
  border-radius: 8px;
  display: flex;
  margin-bottom: 24px;
  padding: 6px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 4px;
  }
`;

const Tab = styled.button`
  background: ${props => props.active ? '#1a1d23' : 'transparent'};
  border: none;
  border-radius: 6px;
  color: ${props => props.active ? 'white' : '#6b7280'};
  cursor: pointer;
  flex: 1;
  padding: 12px;
  transition: all 0.3s ease;
  font-weight: ${props => props.active ? '600' : '400'};
  font-size: 16px;
  
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
  }
  
  &:hover {
    color: ${props => props.active ? 'white' : '#a1a1aa'};
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
  
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #4a5568;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const BonusList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const BonusItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a1a1aa;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  @media (max-width: 768px) {
    padding: 12px;
  }
  
  &:hover {
    background-color: #24272c;
  }
`;

const BonusInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const BonusIcon = styled.img`
  width: 32px;
  height: 32px;
  
  @media (max-width: 768px) {
    width: 28px;
    height: 28px;
  }
`;

const BonusType = styled.span`
  color: #d1d5db;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const BonusAmount = styled.span`
  color: #d1d5db;
  font-weight: 600;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  @media (max-width: 768px) {
    gap: 12px;
  }
`;

const TransactionItem = styled.div`
  background-color: #24272c;
  border-radius: 8px;
  padding: 16px;
  
  @media (max-width: 768px) {
    padding: 12px;
  }
`;

const TransactionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const TransactionType = styled.span`
  color: #d1d5db;
  font-weight: 500;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionAmount = styled.span`
  color: #10b981;
  font-weight: 600;
  font-size: 16px;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TransactionDetails = styled.div`
  display: flex;
  justify-content: space-between;
  color: #6b7280;
  font-size: 14px;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const Status = styled.span`
  color: ${props => props.status === 'Completed' ? '#10b981' : '#f59e0b'};
  font-size: 14px;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const BonusDetails = (data) => {
  const [activeTab, setActiveTab] = useState('Overview');
  
  const getImagePath = (i) => {
    if(i === "dailybonus"){
      return "https://hash.game/modules/bonus2/assets/icon-quest-5a485063.png"
    }
  }

  const bonusTypes = [
    { key: 'dailybonus', type: "Daily Bonus", claimed: data[0]?.dailybonus || 0, icon: "https://hash.game/modules/bonus2/assets/icon-quest-5a485063.png" },
    { key: 'luckyspin', type: "Lucky Spin", claimed: data[0]?.luckyspin || 0, icon: "https://hash.game/modules/bonus2/assets/icon-lucky-spin-cc90b095.png" },
    { key: 'rollcompetitionbonus', type: "Roll Competition", claimed: data[0]?.rollcompetitionbonus || 0, icon: "https://hash.game/modules/bonus2/assets/icon-roll-a05d018c.png" },
    { key: 'weeklybonus', type: "Weekly Bonus", claimed: data[0]?.weeklybonus || 0, icon: "https://hash.game/modules/bonus2/assets/icon-weekly-fc8e3574.png" },
    { key: 'monthlybonus', type: "Monthly Bonus", claimed: data[0]?.monthlybonus || 0, icon: "https://hash.game/modules/bonus2/assets/icon-monthly-5aefcffd.png" },
    { key: 'depositbonus', type: "Deposit Bonus", claimed: data[0]?.depositbonus || 0, icon: "https://hash.game/modules/bonus2/assets/icon-deposit-67dfc30e.png" }
  ];

  const renderOverviewTab = () => (
    <BonusList>
      {bonusTypes.map(bonus => (
        <BonusItem key={bonus.key}>
          <BonusInfo>
            <BonusIcon src={bonus.icon} alt={bonus.type} />
            <BonusType>{bonus.type}</BonusType>
          </BonusInfo>
          <BonusAmount>${bonus.claimed.toFixed(2)}</BonusAmount>
        </BonusItem>
      ))}
    </BonusList>
  );

  const renderTransactionTab = () => (
    <TransactionList>
      {mockTransactions.map(transaction => (
        <TransactionItem key={transaction.id}>
          <TransactionHeader>
            <TransactionType>{transaction.type}</TransactionType>
            <TransactionAmount>+${transaction.amount.toFixed(2)}</TransactionAmount>
          </TransactionHeader>
          <TransactionDetails>
            <span>{transaction.date}</span>
            <Status status={transaction.status}>{transaction.status}</Status>
          </TransactionDetails>
        </TransactionItem>
      ))}
    </TransactionList>
  );

  return (
    <Container>
      <TabContainer>
        <Tab 
          active={activeTab === 'Overview'} 
          onClick={() => setActiveTab('Overview')}
        >
          Overview
        </Tab>
        <Tab 
          active={activeTab === 'Transaction'} 
          onClick={() => setActiveTab('Transaction')}
        >
          Transaction
        </Tab>
      </TabContainer>
      <ContentContainer>
        {activeTab === 'Overview' ? renderOverviewTab() : renderTransactionTab()}
      </ContentContainer>
    </Container>
  );
};

export default BonusDetails;