import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Spinner, Alert, FormControl, Container, Row, Col } from "react-bootstrap";
import Carousel from "../../Parts/Home/crousel";
import { Helmet } from 'react-helmet';
import Popular from "./Popular";
import Games from "./Games";
import Slots from "../../../Slots/Slots";
import { BRAND, isMobile } from "../../../../Helper";
import { withCookies } from 'react-cookie';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSpinner } from '@fortawesome/free-solid-svg-icons';
import CustomSlider from "../../../Components/CustomSlider";
import BannerGames from "../../../Components/BannerGames";
import GameList from "../../HomeOrignals";
import { PickOption } from "../../../Games/Lottery";
import CasinoLeaderboard from "../../../Components/HelloGamesTable";
import { ChevronLeft, ChevronRight, Play } from "lucide-react";
import Banner from "../../../Components/Banner";
import LatestWins from "../../../Components/LatestWins";
import GamingCards from "../../../Components/GamingCards";
import SkylaGameCards from "../../../Components/SkylaGamingCard";
import LiveSportsCards from "../../../Components/LiveSports";
import LiveSports from "../../../Components/LiveSports";
import PaymentBar from "../../../Components/PaymentBar";
import SkylaSlider from "../../../Components/SkylaBannerSlider";
import MobilePaymentBR from "../../../Components/MobilePaymetBar";
const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1b2336;
`;

const FullScreenIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  display: ${(props) => (props.loading ? 'none' : 'block')};
`;
const ResponsiveImage = styled.img`
  object-fit: cover;
  width: 140px;
  height: 140px;
  border-radius:20px;

  @media (min-width: 467px) {
    width: 188px;
    height: 188px;
  }
`;


const BackButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: none;
  border: none;
  color: #000;
  font-size: 24px;
  cursor: pointer;
`;

const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #000;
`;





const SliderContainer = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
  overflow: hidden;
`;

const SliderTrack = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.translateX}%);
`;

const SliderItem = styled.div`
  flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background-color: #f2f2f2;
`;

const SliderNavigation = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
`;

const NavigationButton = styled.button`
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
`;
const LottoCard = styled.button`
  background-color: #0E1423;
 border-radius:12px;
 width:100%
   margin-bottom:12px;
`;

const ContainerPlay = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    // padding: 10px;
    justify-content: center;
  }

  @media (max-width: 767px) {
    gap: 8px;
    // padding: 5px;
  }
`;

const PickOptionWrapper = styled.div`
  flex: 0 0 auto;
  width: calc(50% - 6px);
  scroll-snap-align: start;

  @media (min-width: 768px) {
    width: 100%;
  }
`;


const HiddenOnSmallScreens = styled.div`
display:flex;
  @media (max-width: 768px) {
    display: none;
  }
`;

const GamesWrapper = styled.div`
  background-color: #1C1E22;
  padding: 2px;
  border-radius: 0 20px 20px 20px; /* top-left | top-right | bottom-right | bottom-left */
  position: relative;
  margin-top: 40px !important;
 
  @media (max-width: 768px) {
    margin-top: 100px !important;
  }
`;


const GamesTopWrapper = styled.div`
  background-color: #1C1E22;
  padding: 2px;
  border-radius: 0 20px 20px 20px; /* top-left | top-right | bottom-right | bottom-left */
  position: relative;
  margin-top: 40px !important;
   margin-top: 100px !important;
  @media (max-width: 768px) {
    margin-top: 100px !important;
  }
`;
const GridContainer = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin:12px 12px;
  width: 100%;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr);
  // Tablet view (3 columns)
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  // Desktop view (4 columns)
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;
const GridItem = styled.div`
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;


const GridContainerNew = styled.div`
  display: grid;
  gap: 10px;
  padding: 0rem;
  margin:12px 12px;
  width: 100%;
  padding-right:17px;
  // Mobile view (2 columns)
  grid-template-columns: repeat(2, 1fr) !important;
  // Tablet view (3 columns)
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  // Desktop view (4 columns)
  @media (min-width: 1024px) {
    grid-template-columns: repeat(6, 1fr) !important;
  }
`;
const GridItemNew = styled.div`
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
  }
`;




const NavWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
  margin-top: 24px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  /* Reduce margins on mobile screens */
  @media (max-width: 768px) {
    margin-bottom: -17px;
    margin-top: 12px;
  }
`;


const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const Title = styled.h3`
  padding: 0.5rem;
  margin: 0;
  font-size: 10px;
  text-align: left;
  color: rgb(152, 167, 181);
`;
const TopTitleContainer = styled.h3`
  background-color: #1C1E22;
  border-radius: 15px;
  position: absolute;
  top: -71px;
  left: 0;
  z-index: 1;
  padding: 10px;
  margin: 0;
`;
const AllButtonRigthTitleContainer = styled.div`

  position: absolute;
  display:flex;
  gap:10px;
  align-items:center;
  top: -57px;
  right: 0;
  color:#7F898C;
  font-weight:bold;
  z-index: 1;
  margin: 0;
`;


const AllButtonTopTitleContainer = styled.div`
  background-color: #1C1E22;
  border-radius: 15px;
  display:flex;
  justify-caontent:center;
  align-items:center;
  color:#7F898C;
  font-weight:bold;
  z-index: 1;
  padding: 5px 23px;
  margin: 0;
  cursor:pointer;
`;
const PaginationButton = styled.div`
  background-color: #1C1E22;
  border-radius: 50%;
  display:flex;
  justify-caontent:center;
  align-items:center;
  color:#7F898C;
  font-weight:bold;
  z-index: 1;
  padding: 5px;

`;


// const NavBar = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
// `;

// const NavBarEnd = styled.div`
//   background-color: #191A1E;
//   padding: 2px;
//   display: flex;
//   gap: 5px;
//   border-radius: 24px;
//   overflow-x: auto;
//   width:60% !important;
//   // flex:1 1;
//   white-space: nowrap;
//   -webkit-overflow-scrolling: touch;
//   scrollbar-width: none;
//   -ms-overflow-style: none;

//   &::-webkit-scrollbar {
//     display: none;
//   }

//   @media (max-width: 768px) {
//     // max-width: 100%;
//   }
// `;

const NavItem = styled.button`
  display: flex;
  align-items: center;
  background-color: ${props => props.active ? '#23262B' : 'transparent'};
  color: ${props => props.active ? '#ffffff' : '#888888'};
  border: none;
  padding: 12px 16px;
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 12px;
  white-space: nowrap;
  flex-shrink: 0;
 

  &:hover {
    background-color: #2a2a2a;
  }
`;
const ProviderLogo = styled.img`
object-fit:contain;
height:1.6rem;
width:80%;
`;
const ProviderWrapper = styled.div`
margin-top:50px !important;
margin-bottom:50px !important;
`;
const ProviderHeadingWrapper = styled.div`
margin-top:10px !important;
margin-bottom:10px !important;
display:flex;
justify-content:space-between;
`;




const ProviderGridContainer = styled.div.attrs({ className: 'provide-grid-container' })`
  display: grid;
  gap: 1px;
  background-color: #1e2124;
  padding: 1px;
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  
  // Mobile layout (3 columns, scrollable)
  @media (max-width: 767px) {
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: minmax(100px, 1fr);
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  
  // Tablet layout (3 columns)
  @media (min-width: 768px) and (max-width: 1023px) {
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    grid-auto-columns: minmax(150px, 1fr);
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }
  
  // Desktop layout (9 columns, 2 rows)
  @media (min-width: 1024px) {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, 1fr);
    overflow: hidden;
  }
`;

const ProviderGridItem = styled.div.attrs({ className: 'provide-grid-item' })`
  background-color: #313439;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  scroll-snap-align: start;
  
  @media (max-width: 1023px) {
    aspect-ratio: 16 / 9;
  }
`;





const NavBar = styled.nav`
  display: inline-flex; // Changed to inline-flex
  justify-content: flex-end;
  background-color: #191A1E;
  padding: 4px;
  border-radius: 26px;
  overflow: hidden; // Changed from overflow-x
`;

const NavBarEnd = styled.div`
  display: flex;
  width: 100%;
`;

const TabSwitcher = styled.div`
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const GameOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;
 
  

`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#2c3137' : 'transparent'};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 26px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${props => props.active ? '#2c3137' : '#1c1e22'};
  }
`;

const FlexArrange = styled.div`
  display: flex;
  align-items: center;
`;

const TabImage = styled.img`
  height: 28px;
  width: 28px;
  object-fit: contain;
  margin-right: 8px;
  filter: ${props => props.active ? 'none' : 'grayscale(100%)'};
  transition: filter 0.3s ease;
`;

const TabText = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;




const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const IconBg = styled.div`
  padding: 6px 8px;
  background-color: #394142;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #4a5354;
  }
`;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  // margin-top: 20px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 8px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const GameCard = styled.div`
  min-width: 142px;
  height: 195px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;

  &:hover {
  

    ${GameOverlay} {
      opacity: 1;
    }
  
    transform: translateY(-5px);

  }

   @media (max-width: 768px) {
   min-width: 115px;
    height: 160px;
  }
`;

const GameImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const PlayIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayIcon = styled(Play)`
  color: #fff;
  font-size: 24px;
`;
const GameTitle = styled.h3`
  color: white;
  font-size: 16px;
  margin: 12px 0 0 0;
  font-weight: bold;
  text-align: center;
`;

const TopTitle = styled.h3`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;




class Home extends Component {
  constructor(props) {
    super(props);
    const loggedStatus = localStorage.getItem('logged') === 'true';
    this.state = {
      padding: 'py-2',
      selectedSection: 'home',
      sectionIndex: 0,
      slideSelected: null,
      gamesEvolution: [],
      gamesPragmatic: [],
      gamesPragmaticlive: [],
      gamesEvoplay: [],
      gamesGenisis: [],
      gamesPgsoft: [],
      gamesLiveCasinoProvider: [],
      gamesSpribe: [],
      gamesSports: [],
      gamesMostExpensive: [],
      error: null,
      loading: false,
      message: null,
      showMore: false,
      showMoreP: false,
      showMoreO: false,
      showMoreLCP: false,
      showMoreSP: false,
      showMoreSB: false,
      showMoreEvo: false,
      isMobileView: window.innerWidth <= 768,
      searchQuery: '',
      showIframe: false,
      iframeUrl: '',
      iframeLoading: true,
      showAll: false,
      showAllData: false,
      isLogged: loggedStatus,
      activeItem: 'Lobby',
      canScrollLeft: false,
      canScrollRight: true

    };
    this.gridRef = React.createRef();
    this.sections = ["/assets/images/evolutionicon.png", "/assets/images/ezubi.png", "/assets/images/pragmatic.png", "/assets/images/netent.png", "/assets/images/novomatic.png"];
    this.livecasino = ["/assets/images/live1.png", "/assets/images/live2.png", "/assets/images/live3.jpg", "/assets/images/live4.jpg", "/assets/images/live5.jpg", "/assets/images/live6.png"];
    this.itemsToShow = 5;
    this.containerPlayRef = React.createRef();
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.scrollContainerRef = React.createRef();
    this.scrollContainerLiveRef = React.createRef();
    this.scrollContainerLiveAllRef = React.createRef();
  }

  componentDidMount() {
    this.checkScrollPosition();
    // Add scroll and resize event listeners
    if (this.gridRef.current) {
      this.gridRef.current.addEventListener('scroll', this.checkScrollPosition);
      window.addEventListener('resize', this.checkScrollPosition);
    }
  }

  componentWillUnmount() {
    // Clean up event listeners
    if (this.gridRef.current) {
      this.gridRef.current.removeEventListener('scroll', this.checkScrollPosition);
      window.removeEventListener('resize', this.checkScrollPosition);
    }
  }

  checkScrollPosition = () => {
    if (this.gridRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = this.gridRef.current;

      this.setState({
        canScrollLeft: scrollLeft > 0,
        canScrollRight: Math.ceil(scrollLeft) < (scrollWidth - clientWidth)
      });
    }
  };

  componentDidMount() {
    // Get the logged state from localStorage when component mounts
    const loggedStatus = localStorage.getItem('logged') === 'true';
    this.setState({ isLogged: loggedStatus });
  }

  handleScroll = (direction) => {
    if (this.gridRef.current) {
      const scrollAmount = this.gridRef.current.clientWidth / 2;
      this.gridRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  handleShowAllData = () => {
    this.setState(prevState => ({ showAllData: !prevState.showAllData }));
  }

  handleMouseDown(e) {
    this.isDown = true;
    this.startX = e.pageX - this.containerPlayRef.current.offsetLeft;
    this.scrollLeft = this.containerPlayRef.current.scrollLeft;
  }

  handleShowAllClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    this.setState({ showAll: !this.state.showAll });
  }
  handleShowAllClickEvo = (e) => {
    e.preventDefault(); // Prevent default link behavior
    this.setState({ showMoreEvo: !this.state.showMoreEvo });
  }


  handleMouseLeave() {
    this.isDown = false;
  }

  handleMouseUp() {
    this.isDown = false;
  }

  handleMouseMove(e) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.containerPlayRef.current.offsetLeft;
    const walk = (x - this.startX) * 2;
    this.containerPlayRef.current.scrollLeft = this.scrollLeft - walk;
  }
  handleItemClick = (item) => {
    this.setState({ activeItem: item });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeItem !== this.state.activeItem) {
      console.log(`Active item changed to: ${this.state.activeItem}`);
      // Add any additional side effects or logic you want to run on activeItem change
    }
  }


  componentDidMount() {
    const containerPlay = this.containerPlayRef.current;
    containerPlay.addEventListener('mousedown', this.handleMouseDown);
    containerPlay.addEventListener('mouseleave', this.handleMouseLeave);
    containerPlay.addEventListener('mouseup', this.handleMouseUp);
    containerPlay.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    const containerPlay = this.containerPlayRef.current;
    containerPlay.removeEventListener('mousedown', this.handleMouseDown);
    containerPlay.removeEventListener('mouseleave', this.handleMouseLeave);
    containerPlay.removeEventListener('mouseup', this.handleMouseUp);
    containerPlay.removeEventListener('mousemove', this.handleMouseMove);
  }

  handleGameLaunch = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');

      if (!uid) {
        throw new Error("Please login to play live game");
      }

      const response = await fetch('https://api.ibitplay.com/game_launch', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          provider_code: provider,
          game_code: gameCode
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === 1) {
        this.setState({ iframeUrl: result.launch_url, showIframe: true, iframeLoading: true });
      } else if (result.status === 0 && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.msg);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleGameLaunchNew = async (gameCode, provider) => {
    this.setState({ loading: true, message: null });
    try {
      const { cookies } = this.props;
      const uid = cookies.get('uid');
      if (!uid) {
        throw new Error("Please login to play live game");
      }
      const response = await fetch('https://api.ibitplay.com/game_launch_new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_code: String(uid),
          game_code: gameCode
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status === "success" && result.content && result.content.game && result.content.game.url) {
        console.log("urllll", result.content.game.url);
        this.setState({ iframeUrl: result.content.game.url, showIframe: true, iframeLoading: true });
      } else if (result.status === "fail" && result.detail === "user_code is not allowed to be empty") {
        throw new Error("Please login to play live game");
      } else {
        throw new Error("Failed to launch game: " + result.error);
      }
    } catch (error) {
      this.setState({ message: { type: 'error', text: error.message } });
    } finally {
      this.setState({ loading: false });
      setTimeout(() => {
        this.setState({ message: null });
      }, 5000);
    }
  };

  handleBackButton = () => {
    this.setState({ showIframe: false, iframeUrl: '', iframeLoading: true });
  };

  handleIframeLoad = () => {
    this.setState({ iframeLoading: false });
  };

  componentDidMount() {
    this._isMounted = true;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (isMobile()) {
      this.setState({ padding: 'p-1' });
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.fetchGames("EVOLUTION").then(games => {
      this.setState({ gamesEvolution: games.map(game => ({ ...game, provider_code: "EVOLUTION" })) });
    });
    this.fetchGames("PRAGMATICLIVE").then(games => {
      this.setState({ gamesPragmatic: games.map(game => ({ ...game, provider_code: "PRAGMATICLIVE" })) });
    });
    this.fetchGames("PRAGMATIC").then(games => {
      this.setState({ gamesPragmaticlive: games.map(game => ({ ...game, provider_code: "PRAGMATIC" })) });
    });
    this.fetchGames("EVOPLAY").then(games => {
      this.setState({ gamesEvoplay: games.map(game => ({ ...game, provider_code: "EVOPLAY" })) });
    });
    this.fetchGames("DREAMTECH").then(games => {
      this.setState({ gamesGenisis: games.map(game => ({ ...game, provider_code: "DREAMTECH" })) });
    });
    this.fetchGames("PGSOFT").then(games => {
      this.setState({ gamesPgsoft: games.map(game => ({ ...game, provider_code: "PGSOFT" })) });
    });
    // this.fetchGamesNew("live_dealers").then(games => {
    //   const filteredGames = games.filter((game, index) => [1,6,0].includes(index));
    //   this.setState({ gamesLiveCasinoProvider: filteredGames.map(game => ({ ...game, provider_code: "live_dealers" })) });
    // });
    this.fetchGamesNew("spribe").then(games => {
      const filteredGames = games.filter((game, index) => [8, 5, 6, 7].includes(index));
      this.setState({ gamesSpribe: filteredGames.map(game => ({ ...game, provider_code: "spribe" })) });
    });
    // this.fetchGamesNew("more_expensive").then(games => {
    //   this.setState({ gamesMostExpensive: games.map(game => ({ ...game, provider_code: "more_expensive" })) });
    //   gamesLiveCasinoProvider.concat(gamesMostExpensive);
    // }); 
    this.fetchGamesNew("more_expensive").then(moreExpensiveGames => {
      const updatedMoreExpensiveGames = moreExpensiveGames.map(game => ({ ...game, provider_code: "more_expensive" }));
      this.setState(prevState => ({
        gamesMostExpensive: updatedMoreExpensiveGames,
        gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedMoreExpensiveGames)
      }));
    });
    this.fetchGamesNew("live_dealers").then(liveDealerGames => {
      const filteredLiveDealerGames = liveDealerGames.filter((game, index) => [1, 6, 0].includes(index));
      const updatedLiveDealerGames = filteredLiveDealerGames.map(game => ({ ...game, provider_code: "live_dealers" }));
      this.setState(prevState => ({
        gamesLiveCasinoProvider: (prevState.gamesLiveCasinoProvider || []).concat(updatedLiveDealerGames)
      }));
    });

    this.fetchGamesNew("sport_betting").then(games => {
      this.setState({ gamesSports: games.map(game => ({ ...game, provider_code: "sport_betting" })) });
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._isMounted = false;
  }

  fetchGames = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list?s=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      return result.games;
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  fetchGamesNew = async (provider) => {
    try {
      const response = await fetch(`https://api.ibitplay.com/game-list-new?lable=${provider}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.games && result.games[provider]) {
        return result.games[provider];
      } else {
        throw new Error(`No games found for the provider: ${provider}`);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error.message);
      return [];
    }
  };

  handleResize = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.setState({ isMobileView: width <= 768 });
  };

  handleClick = (e) => {
    this.setState({ selectedSection: e });
  };

  handleArrowClick = (direction) => {
    const { sectionIndex } = this.state;
    const { sections, itemsToShow } = this;
    const totalSections = sections.length;

    let newIndex;

    if (direction === 'left') {
      newIndex = (sectionIndex - itemsToShow + totalSections) % totalSections;
    } else {
      newIndex = (sectionIndex + itemsToShow) % totalSections;
    }

    this.setState({ selectedSection: sections[newIndex], sectionIndex: newIndex });
  };

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleShowMoreP = () => {
    this.setState({ showMoreP: !this.state.showMoreP });
  };

  handleShowMoreO = () => {
    this.setState({ showMoreO: !this.state.showMoreO });
  };

  handleShowMoreLCP = () => {
    this.setState({ showMoreLCP: !this.state.showMoreLCP });
  };

  handleShowMoreSP = () => {
    this.setState({ showMoreSP: !this.state.showMoreSP });
  };

  handleShowMoreSB = () => {
    this.setState({ showMoreSB: !this.state.showMoreSB });
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  getFilteredGames = (games) => {
    const { searchQuery } = this.state;
    const { companies } = this.props;
    const { canScrollLeft, canScrollRight } = this.state;

    if (!searchQuery) return games;
    return games.filter(game => game.game_name && game.game_name.toLowerCase().includes(searchQuery.toLowerCase()));
  };

  handleScrollLeft = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: -400,
        behavior: 'smooth'
      });
    }
  }

  handleScrollRight = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollBy({
        left: 400,
        behavior: 'smooth'
      });
    }
  }

  handleScrollLeftLive = () => {
    if (this.scrollContainerLiveRef.current) {
      this.scrollContainerLiveRef.current.scrollBy({
        left: -400,
        behavior: 'smooth'
      });
    }
  }

  handleScrollRightLive = () => {
    if (this.scrollContainerLiveRef.current) {
      this.scrollContainerLiveRef.current.scrollBy({
        left: 400,
        behavior: 'smooth'
      });
    }
  }
  handleScrollLeftLiveAll = () => {
    if (this.scrollContainerLiveAllRef.current) {
      this.scrollContainerLiveAllRef.current.scrollBy({
        left: -400,
        behavior: 'smooth'
      });
    }
  }

  handleScrollRightLiveAll = () => {
    if (this.scrollContainerLiveAllRef.current) {
      this.scrollContainerLiveAllRef.current.scrollBy({
        left: 400,
        behavior: 'smooth'
      });
    }
  }

  render() {
    let { content, t } = this.props;
    const { activeItem } = this.state;
    const { showAll, showMoreEvo } = this.state;
    const { canScrollLeft, canScrollRight, isLogged } = this.state;

    console.log("is loge", isLogged)

    console.log("show all refelcting", showAll)
    const { selectedSection, sectionIndex, slideSelected, gamesEvolution, gamesLiveCasinoProvider, gamesSpribe, gamesPragmatic, gamesPragmaticlive, gamesSports, gamesEvoplay, gamesGenisis, gamesPgsoft, gamesMostExpensive, loading, message, searchQuery, showMore, showMoreP, showMoreO, showMoreLCP, showMoreSP, showMoreSB, isMobileView, padding, showIframe, iframeUrl, iframeLoading } = this.state;
    const { sections, itemsToShow, livecasino } = this;
    const tabs = [
      { name: 'Lobby', image: '/assets/images/hellogames/lobby.webp' },
      { name: 'Original', image: '/assets/images/hellogames/originalcasino.webp' },
      { name: 'Slots', image: '/assets/images/hellogames/slots.webp' },
      { name: 'Live Casino', image: '/assets/images/hellogames/livecasino.webp' },
    ];
    const companies = [
      '/assets/images/Providers/evolutiongaming.webp', '/assets/images/Providers/netent.webp', '/assets/images/Providers/bet.webp', '/assets/images/Providers/quickspin.webp', '/assets/images/Providers/gameart.webp', '/assets/images/Providers/playstone.webp',
      '/assets/images/Providers/bgaming.webp', '/assets/images/Providers/ka.webp', '/assets/images/Providers/wazdan.webp', '/assets/images/Providers/spinn.webp', '/assets/images/Providers/evoplay.webp', '/assets/images/Providers/thunder.webp',
      '/assets/images/Providers/endrophina.webp', '/assets/images/Providers/bgaming.webp', '/assets/images/Providers/habanero.webp', '/assets/images/Providers/becom.webp', '/assets/images/Providers/platipus.webp', '/assets/images/Providers/tomhron.webp'
    ];


    const visibleSections = sections.slice(sectionIndex, sectionIndex + itemsToShow);

    const filteredGamesEvolution = this.getFilteredGames(gamesEvolution);
    const filteredGamesPragmatic = this.getFilteredGames(gamesPragmatic);
    const { showAllData } = this.state;

    const filteredGamesEvoplay = this.getFilteredGames(gamesEvoplay);
    const filteredGamesGenisis = this.getFilteredGames(gamesGenisis);
    const filteredGamesPgsoft = this.getFilteredGames(gamesPgsoft);

    const displayGamesEvoplay = isMobileView
      ? (showAllData ? filteredGamesEvoplay.slice(0, 12) : filteredGamesEvoplay.slice(0, 2))
      : (showAllData ? filteredGamesEvoplay : filteredGamesEvoplay.slice(0, 6));

    console.log("display evo", displayGamesEvoplay.length)

    const displayGamesGenisis = isMobileView
      ? (showAllData ? filteredGamesGenisis.slice(0, 12) : filteredGamesGenisis.slice(0, 2))
      : (showAllData ? filteredGamesGenisis : filteredGamesGenisis.slice(0, 2));

    console.log("display geneisis", displayGamesGenisis.length)

    const displayGamesPgsoft = isMobileView
      ? (showAllData ? filteredGamesPgsoft.slice(0, 12) : filteredGamesPgsoft.slice(0, 2))
      : (showAllData ? filteredGamesPgsoft : filteredGamesPgsoft.slice(0, 6));

    console.log("gemessoft", displayGamesPgsoft.length)

    console.log("is mobile view enabled", isMobileView)


    const filteredSpribe = this.getFilteredGames(gamesSpribe);
    const filteredSports = this.getFilteredGames(gamesSports);
    const filtergamespragamaticlive = this.getFilteredGames(gamesPragmaticlive);
    const filtergamesLiveCasinoProvider = this.getFilteredGames(gamesLiveCasinoProvider);
    const displayedGames = isMobileView ? gamesEvolution : (showMore ? gamesEvolution : gamesEvolution.slice(0, 5));
    const displayedGamesSP = isMobileView ? gamesSpribe : (showMoreSP ? gamesSpribe : gamesSpribe.slice(0, 5));
    const displayedGamesSB = isMobileView ? gamesSports : (showMoreSB ? gamesSports : gamesSports.slice(0, 5));
    const displayedGamesLCP = isMobileView ? gamesLiveCasinoProvider : (showMoreLCP ? gamesLiveCasinoProvider : gamesLiveCasinoProvider.slice(0, 5));
    const displayGamesP = isMobileView ? gamesPragmatic : (showMoreP ? gamesPragmatic : gamesPragmatic.slice(0, 5));
    const skylaSlots = [...gamesPragmaticlive, ...gamesEvolution, ...gamesPragmatic]
    console.log("skyle slots", skylaSlots)

    const displayGamesPL = isMobileView
      ? (showAll ? gamesPragmaticlive.slice(0, 10) : gamesPragmaticlive.slice(0, 6))
      : (showAll ? gamesPragmaticlive : gamesPragmaticlive.slice(0, 12));
    //made to show only for live games
    const displayedGamesLive = isMobileView
      ? (showMoreEvo ? gamesEvolution.slice(0, 10) : gamesEvolution.slice(0, 6))
      : (showMoreEvo ? gamesEvolution : gamesEvolution.slice(0, 12));

    console.log("display", displayGamesPL)


    console.log("image url", displayGamesPL)

    // if (showIframe) {
    //   return (
    //     <FullScreenContainer>
    //       {this.state.iframeLoading && (
    //         // <Loader>
    //         //   <FontAwesomeIcon icon={faSpinner} spin />
    //         // </Loader>
    //         <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
    //       )}
    //       <FullScreenIframe
    //         src={iframeUrl}
    //         title="Game Iframe"
    //         onLoad={this.handleIframeLoad}  
    //         loading={this.state.iframeLoading} 
    //       />
    //       <BackButton onClick={this.handleBackButton}>
    //         <FontAwesomeIcon icon={faArrowLeft} />
    //       </BackButton>
    //     </FullScreenContainer>
    //   );
    // }
    const imageStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '250px',
      animation: 'fadeinout 2s infinite',
    };
    const animationStyle = `
      @keyframes fadeinout {
        0%, 100% { opacity: 0; }
        50% { opacity: 1; }
      }
    `;
    if (showIframe) {
      return (
        <FullScreenContainer>
          <style>{animationStyle}</style>
          {this.state.iframeLoading && (
            <img src="assets/images/lg.png" alt="Loading" style={imageStyle} />
          )}
          <FullScreenIframe
            src={iframeUrl}
            title="Game Iframe"
            onLoad={this.handleIframeLoad}
            loading={this.state.iframeLoading}
          />
          <BackButton onClick={this.handleBackButton}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </BackButton>
        </FullScreenContainer>
      );
    }

    const items = [
      { imageUrl: 'https://example.com/image1.jpg', title: 'Slide 1' },
      { imageUrl: 'https://example.com/image2.jpg', title: 'Slide 2' },
      { imageUrl: 'https://example.com/image3.jpg', title: 'Slide 3' },
      { imageUrl: 'https://example.com/image4.jpg', title: 'Slide 4' },
      { imageUrl: 'https://example.com/image5.jpg', title: 'Slide 5' },
    ];

    const games = [{
      "id": "crash",
      "title": "CRASH",
      "description": "Lower risk, Higher reward",
      "multiplier": "999x",
      "imageUrl": "/assets/images/hellogames/hello1.webp"
    },
    {
      "id": "crash-trenball",
      "title": "CRASH TRENBALL",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello2.webp"
    },
    {
      "id": "mine",
      "title": "MINE",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "ultimate-dice",
      "title": "ULTIMATE DICE",
      "description": "",
      "multiplier": "9900x",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    {
      "id": "oriental-beauties",
      "title": "ORIENTAL BEAUTIES",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello1.webp"
    },
    {
      "id": "classic-dice",
      "title": "CLASSIC DICE",
      "description": "Easy to win like a real casino",
      "imageUrl": "/assets/images/hellogames/hello2.webp"
    },
    {
      "id": "plinko",
      "title": "PLINKO",
      "description": "",
      "multipliers": ["2.1x", "0.5x", "420x"],
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "cave-of-plunder",
      "title": "CAVE OF PLUNDER",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    {
      "id": "wheel",
      "title": "WHEEL",
      "description": "",
      "multipliers": ["39.6x", "49.50x", "19.8x"],
      "imageUrl": "/assets/images/hellogames/hello1.webp"
    },
    {
      "id": "saviour-sword",
      "title": "SAVIOUR SWORD",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello2.webp"
    },
    {
      "id": "mine",
      "title": "MINE",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "ultimate-dice",
      "title": "ULTIMATE DICE",
      "description": "",
      "multiplier": "9900x",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    ]

    const Livecasinogames = [
      {
        "id": "crash",
        "title": "CRASH",
        "description": "Lower risk, Higher reward",
        "multiplier": "999x",
        "banner": "/assets/images/liveCasinos/l1.webp"
      },
      {
        "id": "crash-trenball",
        "title": "CRASH TRENBALL",
        "description": "",
        "banner": "/assets/images/liveCasinos/l2.webp"
      },
      {
        "id": "mine",
        "title": "MINE",
        "description": "",
        "banner": "/assets/images/hellogames/hello3.webp"
      },
      {
        "id": "ultimate-dice",
        "title": "ULTIMATE DICE",
        "description": "",
        "multiplier": "9900x",
        "banner": "/assets/images/hellogames/hello4.webp"
      },
      {
        "id": "oriental-beauties",
        "title": "ORIENTAL BEAUTIES",
        "description": "",
        "banner": "/assets/images/hellogames/hello1.webp"
      },
      {
        "id": "classic-dice",
        "title": "CLASSIC DICE",
        "description": "Easy to win like a real casino",
        "banner": "/assets/images/hellogames/hello2.webp"
      },
      {
        "id": "plinko",
        "title": "PLINKO",
        "description": "",
        "multipliers": ["2.1x", "0.5x", "420x"],
        "banner": "/assets/images/hellogames/hello3.webp"
      },
      {
        "id": "cave-of-plunder",
        "title": "CAVE OF PLUNDER",
        "description": "",
        "banner": "/assets/images/hellogames/hello4.webp"
      },
      {
        "id": "wheel",
        "title": "WHEEL",
        "description": "",
        "multipliers": ["39.6x", "49.50x", "19.8x"],
        "banner": "/assets/images/hellogames/hello1.webp"
      },
      {
        "id": "saviour-sword",
        "title": "SAVIOUR SWORD",
        "description": "",
        "banner": "/assets/images/hellogames/hello2.webp"
      },
      {
        "id": "mine",
        "title": "MINE",
        "description": "",
        "banner": "/assets/images/hellogames/hello3.webp"
      },
      {
        "id": "ultimate-dice",
        "title": "ULTIMATE DICE",
        "description": "",
        "multiplier": "9900x",
        "banner": "/assets/images/hellogames/hello4.webp"
      },
    ];

    // Duplicate until length is at least 100
    const extendedGames = [];
    while (extendedGames.length < 100) {
      extendedGames.push(...Livecasinogames);
    }

    // Trim to exactly 100 items
    const LivecasinogamesExtended = extendedGames.slice(0, 100);
    const slotsGames = [{
      "id": "crash",
      "title": "CRASH",
      "description": "Lower risk, Higher reward",
      "multiplier": "999x",
      "imageUrl": "/assets/images/slotsnano/s1.webp"
    },
    {
      "id": "crash-trenball",
      "title": "CRASH TRENBALL",
      "description": "",
      "imageUrl": "/assets/images/slotsnano/s1.webp"
    },
    {
      "id": "mine",
      "title": "MINE",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "ultimate-dice",
      "title": "ULTIMATE DICE",
      "description": "",
      "multiplier": "9900x",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    {
      "id": "oriental-beauties",
      "title": "ORIENTAL BEAUTIES",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello1.webp"
    },
    {
      "id": "classic-dice",
      "title": "CLASSIC DICE",
      "description": "Easy to win like a real casino",
      "imageUrl": "/assets/images/hellogames/hello2.webp"
    },
    {
      "id": "plinko",
      "title": "PLINKO",
      "description": "",
      "multipliers": ["2.1x", "0.5x", "420x"],
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "cave-of-plunder",
      "title": "CAVE OF PLUNDER",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    {
      "id": "wheel",
      "title": "WHEEL",
      "description": "",
      "multipliers": ["39.6x", "49.50x", "19.8x"],
      "imageUrl": "/assets/images/hellogames/hello1.webp"
    },
    {
      "id": "saviour-sword",
      "title": "SAVIOUR SWORD",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello2.webp"
    },
    {
      "id": "mine",
      "title": "MINE",
      "description": "",
      "imageUrl": "/assets/images/hellogames/hello3.webp"
    },
    {
      "id": "ultimate-dice",
      "title": "ULTIMATE DICE",
      "description": "",
      "multiplier": "9900x",
      "imageUrl": "/assets/images/hellogames/hello4.webp"
    },
    ]


    const sampleGames = {
      all: Array(50).fill(null).map((_, index) => ({
        id: `all-${index}`,
        title: `Game ${index + 1}`,
        image: `https://bc.imgix.net/game/image/576dbdc8dd.png?_v=4&auto=format&dpr=1.25&w=200`,
        user: `Player${index}`,
        amount: `${(Math.random() * 1000).toFixed(2)} USDT`,
        category: 'all'
      })),
      bcOriginals: Array(50).fill(null).map((_, index) => ({
        id: `bc-${index}`,
        title: `BC Original ${index + 1}`,
        image: `https://bc.imgix.net/game/image/576dbdc8dd.png?_v=4&auto=format&dpr=1.25&w=200`,
        user: `Player${index}`,
        amount: `${(Math.random() * 1000).toFixed(2)} ETH`,
        category: 'bcOriginals'
      })),
      slots: Array(50).fill(null).map((_, index) => ({
        id: `slot-${index}`,
        title: `Slot ${index + 1}`,
        image: `https://bc.imgix.net/game/image/576dbdc8dd.png?_v=4&auto=format&dpr=1.25&w=200`,
        user: `Player${index}`,
        amount: `${(Math.random() * 1000).toFixed(2)} XRP`,
        category: 'slots'
      })),
      liveCasino: Array(50).fill(null).map((_, index) => ({
        id: `casino-${index}`,
        title: `Casino ${index + 1}`,
        image: `https://bc.imgix.net/game/image/576dbdc8dd.png?_v=4&auto=format&dpr=1.25&w=200`,
        user: `Player${index}`,
        amount: `${(Math.random() * 1000).toFixed(2)} SOL`,
        category: 'liveCasino'
      }))
    };

    return (
      <>
        {this._isMounted &&
          <>
            <Helmet>
              <title>{BRAND} - Crypto Casino Games and Crypto Gambling</title>
              <meta name="keywords" content="WEB 3.0 Crypto Casino Games, Online Casino Games, Bitcoin Crash Game, Dice Games, Live Casino Games, Crypto Casino Games, Blockchain Games, Bitcoin Casino Games, Best Crypto Online Game, Crypto Gambling Games, PlayCrash.com Crypto Games" />
              <meta name="description"
                content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
              <meta name="og:title" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
              <meta name="og:description" content="PlayCrash.com is built by gamblers for gamblers. With our unique community and a huge selection of games like Crash, HashDice, Plinko, Slots, and many more." />
            </Helmet>

            {/* Page Content */}
            {content}


            <div style={{ marginTop: '-10px' }}>

              {/* <div>fffff</div> */}
              {/* <CustomSlider /> */}
              {/* <BannerGames /> */}
              {
                isLogged ? (<SkylaSlider />
                ) : (<Banner />)
              }
              <LatestWins
                all={skylaSlots}
                bcOriginals={skylaSlots}
                slots={gamesPragmaticlive}
                liveCasino={LivecasinogamesExtended}

              />
              <GamingCards />

              <Games currentPage="home" t={t} />
              <LiveSports />
              <div style={{ marginTop: "24px" }}>
                <HeaderContainer>
                  <TopTitle>Slots</TopTitle>
                  <HeaderContent>
                    <Link to="/games">
                      <IconBg>
                        <span style={{ fontWeight: "bold", fontFamily: "normal" }}>All</span>
                        <span>
                          <ChevronRight size={14} />
                        </span>
                      </IconBg>
                    </Link>
                    <IconBg onClick={this.handleScrollLeftLive}>
                      <ChevronLeft size={16} />
                    </IconBg>
                    <IconBg>
                      <ChevronRight onClick={this.handleScrollRightLive} size={16} />
                    </IconBg>
                  </HeaderContent>
                </HeaderContainer>
                <CarouselContainer>
                  <ScrollContainer ref={this.scrollContainerRef}>
                    {displayGamesPL?.map((game, index) => (
                      <GameCard
                        key={game.game_code}
                        onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}
                      >
                        <GameImage src={game.banner} alt={`Game ${index}`} />
                        <GameOverlay>
                          <PlayIconContainer>
                            <PlayIcon />
                          </PlayIconContainer>
                        </GameOverlay>
                      </GameCard>
                    ))}
                  </ScrollContainer>
                </CarouselContainer>
              </div>
              <div style={{ marginTop: "24px" }}>
                <HeaderContainer>
                  <TopTitle>
                    Live Casino
                  </TopTitle>
                  <HeaderContent>
                    <Link to="/games">
                      <IconBg>
                        <span style={{ fontWeight: "bold", fontFamily: "normal" }}> All</span>
                        <span>
                          <ChevronRight size={14} />
                        </span>
                      </IconBg>
                    </Link>
                    <IconBg onClick={this.handleScrollLeftLive}>
                      <ChevronLeft size={16} />
                    </IconBg>
                    <IconBg>
                      <ChevronRight onClick={this.handleScrollRightLive} size={16} />
                    </IconBg>
                  </HeaderContent>
                </HeaderContainer>
                <CarouselContainer>

                  <ScrollContainer ref={this.scrollContainerLiveRef}>
                    {displayedGamesLive?.map((game, index) => (
                      <GameCard
                        key={game.game_code}
                        onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}
                      >
                        <GameImage src={game.banner} alt={`Game ${index}`} />
                        <GameOverlay>
                          <PlayIconContainer>
                            <PlayIcon />
                          </PlayIconContainer>
                        </GameOverlay>
                      </GameCard>
                    ))}
                  </ScrollContainer>


                </CarouselContainer>
              </div>

              <div style={{ marginTop: "24px" }}>

             {
              !isMobile() ?    <PaymentBar />:<MobilePaymentBR/>
             }
              </div>



              {/* <NavWrapper>
                <HiddenOnSmallScreens />
                <NavBar>
                  <NavBarEnd>
                    <TabSwitcher>
                      {tabs.map((tab) => (
                        <Tab
                          key={tab.name}
                          active={activeItem === tab.name}
                          onClick={() => this.handleItemClick(tab.name)}
                        >
                          <FlexArrange>
                            <TabImage
                              src={tab.image}
                              alt={tab.name.toLowerCase()}
                              active={activeItem === tab.name}
                            />
                            <TabText>{tab.name}</TabText>
                          </FlexArrange>
                        </Tab>
                      ))}
                    </TabSwitcher>
                  </NavBarEnd>
                </NavBar>

              </NavWrapper> */}

              {/* <GamesWrapper>
                <TopTitleContainer>
                  <div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic' }}>
                      <span style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic', color: '#E70A6C' }}>IN-HOUSE</span>
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic' }}>
                      Games
                    </div>
                  </div>

                </TopTitleContainer>




             

                    <Games t={t} />
              


                {
                  activeItem === 'Lobby' && (

                    <div style={{ marginTop: '12px' }}>

                      <Games t={t} />
                    </div>



                  )
                }

                {
                  activeItem === "Slots" && (
                    <GridContainer>
                      {slotsGames.map((game, index) => (
                        <div key={index}>
                          <GridItem>
                            <ResponsiveImage src={game.imageUrl} alt={game.title} />
                          </GridItem>
                          <Title>{game.title}</Title>
                        </div>
                      ))}
                    </GridContainer>
                  )
                }

                {
                  activeItem === "Live Casino" && (
                    <GridContainer>
                      {games.map((game, index) => (
                        <div key={index}>
                          <GridItem>
                            <ResponsiveImage src={game.imageUrl} alt={game.title} />
                          </GridItem>
                          <Title>{game.title}</Title>
                        </div>
                      ))}
                    </GridContainer>
                  )
                }

              </GamesWrapper> */}



              {/* 
              <GamesTopWrapper>
                <TopTitleContainer>
                  <div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic' }}>
                      FEATURED
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic' }}>
                      FROM<span style={{ fontSize: '16px', fontWeight: 'bold', fontStyle: 'italic', color: '#E70A6C' }}>LIVE CASINO</span>
                    </div>
                  </div>

                </TopTitleContainer>
                <AllButtonRigthTitleContainer>
                  <AllButtonTopTitleContainer to="/slots">All Casino</AllButtonTopTitleContainer>
                  <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <PaginationButton>
                      <ChevronLeft size={20} />
                    </PaginationButton>
                    <PaginationButton>
                      <ChevronRight size={20} />
                    </PaginationButton>
                  </div>
                </AllButtonRigthTitleContainer>



                <GridContainerNew>
                  {Livecasinogames.map((game, index) => (
                    <div key={index}>
                      <GridItem>
                        <ResponsiveImage src={game.imageUrl} alt={game.title} />
                      </GridItem>
                      <Title>{game.title}</Title>
                    </div>
                  ))}
                </GridContainerNew>


              </GamesTopWrapper> */}
              {/* <ProviderWrapper>
                <ProviderHeadingWrapper>
                  <div style={{ fontWeight: 'bold', fontSize: '16px', fontStyle: 'italic' }}>
                    GAME PROVIDERS
                  </div>
                  <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <PaginationButton
                      onClick={() => this.handleScroll('left')}
                      disabled={!canScrollLeft}
                      $isActive={canScrollLeft}
                    >
                      <ChevronLeft size={20} />
                    </PaginationButton>
                    <PaginationButton
                      onClick={() => this.handleScroll('right')}
                      disabled={!canScrollRight}
                      $isActive={canScrollRight}
                    >
                      <ChevronRight size={20} />
                    </PaginationButton>
                  </div>
                </ProviderHeadingWrapper>
                <ProviderGridContainer ref={this.gridRef}>
                  {companies.map((company, index) => (
                    <ProviderGridItem key={index}>
                      <ProviderLogo src={company} alt={`Provider ${index + 1}`} />
                    </ProviderGridItem>
                  ))}
                </ProviderGridContainer>
              </ProviderWrapper> */}

              <div style={{ marginTop: "24px" }}>
                <HeaderContainer>
                  <TopTitle>
                    All Slots
                  </TopTitle>
                  <HeaderContent>
                    <Link to="/games">
                      <IconBg>
                        <span style={{ fontWeight: "bold", fontFamily: "normal" }}> All</span>
                        <span>
                          <ChevronRight size={14} />
                        </span>
                      </IconBg>
                    </Link>
                    <IconBg onClick={this.handleScrollLeftLiveAll}>
                      <ChevronLeft size={16} />
                    </IconBg>
                    <IconBg>
                      <ChevronRight onClick={this.handleScrollRightLiveAll} size={16} />
                    </IconBg>
                  </HeaderContent>
                </HeaderContainer>
                <CarouselContainer>

                  <ScrollContainer ref={this.scrollContainerLiveAllRef}>
                    {[...displayGamesEvoplay, ...displayGamesPgsoft]?.map((game, index) => (
                      <GameCard
                        key={game.game_code}
                        onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}
                      >
                        <GameImage src={game.banner} alt={`Game ${index}`} />
                        <GameOverlay>
                          <PlayIconContainer>
                            <PlayIcon />
                          </PlayIconContainer>
                        </GameOverlay>
                      </GameCard>
                    ))}
                  </ScrollContainer>


                </CarouselContainer>
              </div>



              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">EVOLUTION</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGames.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredGamesEvolution.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>   */}



              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">Sports</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGamesSB.map((game, index) => (
                <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.img} style={{ width: '135px', height: '165px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredSports.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSB}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSB ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card> */}




              {/* Search Bar */}
              {/*} <Container fluid className="py-2">
                <Row>
                  <Col xs={12} className="px-0">
                    <div style={{ position: 'relative', width: '100%' }}>
                      <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '8px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#555" viewBox="0 0 16 16">
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </div>
                      <FormControl
                        type="text"
                        placeholder="Search games..."
                        value={searchQuery}
                        onChange={(e) => this.handleSearch(e.target.value)}
                        style={{
                          padding: '2px 5px 2px 28px',
                          height: '28px',
                          width: 'calc(100% - 10px)',
                          margin: '0 5px',
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Container>*/}

              {/* Filtered Games Display */}
              {searchQuery && (
                <Card className="brd7">
                  <Card.Body className={this.state.padding}>
                    <h4 className="mt-2 mb-2 section-title"> Search Results</h4>
                    <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
                      <div style={{
                        display: 'flex',
                        flexWrap: isMobileView ? 'nowrap' : 'wrap',
                        width: '100%',
                        overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
                        WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
                        msOverflowStyle: 'none', // hide scrollbar in IE and Edge
                        scrollbarWidth: 'none', // hide scrollbar in Firefox
                      }}>
                        {filteredGamesEvolution.concat(filteredGamesPragmatic, filteredGamesEvoplay, filteredGamesGenisis, filteredGamesPgsoft, filtergamespragamaticlive,).map((game, index) => (
                          <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                            <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}



              {/* <Card className="brd7">
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title"> Most Popular</h4>
                  <Popular t={t} />
                </Card.Body>
              </Card>
              <Card className="brd7">
                <Card.Body>
                  <h4 className="mt-2 mb-2 section-title">Lotto</h4>
                  <ContainerPlay className="container-play" ref={this.containerPlayRef}>
                    <PickOptionWrapper>
                      <PickOption title="PICK2" />
                    </PickOptionWrapper>
                    <PickOptionWrapper>
                      <PickOption title="PICK3" />
                    </PickOptionWrapper>
                    <PickOptionWrapper>
                      <PickOption title="PICK4" />
                    </PickOptionWrapper>
                    <PickOptionWrapper>
                      <PickOption title="PICK5" />
                    </PickOptionWrapper>
                  </ContainerPlay>
                </Card.Body>
              </Card> */}
              {/* {isMobileView && ( // Render only if it's mobile view
                <Card style={{ marginTop: -15, marginBottom: 10, width: '110%', marginLeft: -10 }}>
                  <Card.Body className={padding}>
                    <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
                      <div style={{ display: 'flex', width: '100%', overflowY: 'scroll' }}>
                        {visibleSections.map((section, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              borderRadius: 10,
                              backgroundColor: slideSelected === section ? "#90909020" : 'transparent',
                              paddingTop: '2px', // Adjust the top padding as needed
                              paddingBottom: '2px', // Adjust the bottom padding as needed
                            }}
                            onClick={() => this.handleClick(section)}
                          >
                            <img src={section} style={{ width: '117px', height: 'auto', marginRight: 10, marginLeft: 3 }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )} */}

              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title">Live Casino Providers</h4>
                  <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
                    <div style={{
                      display: 'flex',
                      flexWrap: isMobileView ? 'nowrap' : 'wrap',
                      width: '100%',
                      overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
                      WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
                      msOverflowStyle: 'none', // hide scrollbar in IE and Edge
                      scrollbarWidth: 'none', // hide scrollbar in Firefox
                    }}>
                      {displayedGamesLCP.map((game, index) => (
                        <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                          <img src={game.img} style={{ width: '125px', height: '145px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                  {!isMobileView && filtergamesLiveCasinoProvider.length > 5 && (
                    <div className="text-center">
                      <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreLCP}>
                        <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreLCP ? 'Show Less' : 'Show More'}
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card> 

<Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">Sports</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGamesSB.map((game, index) => (
                <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.img} style={{ width: '135px', height: '165px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredSports.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSB}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSB ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">EVOLUTION</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayedGames.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                  <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredGamesEvolution.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMore}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMore ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>  */}

              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title">Spribe</h4>
                  <div className="d-flex w-full" style={{ overflow: 'hidden', flexWrap: 'wrap' }}>
                    <div style={{
                      display: 'flex',
                      flexWrap: isMobileView ? 'nowrap' : 'wrap',
                      width: '100%',
                      overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
                      WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
                      msOverflowStyle: 'none', // hide scrollbar in IE and Edge
                      scrollbarWidth: 'none', // hide scrollbar in Firefox
                    }}>
                      {displayedGamesSP.map((game, index) => (
                        <div key={game.id} onClick={() => this.handleGameLaunchNew(game.id, game.provider_code)} style={{ marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: '3px' }}>
                          <img src={game.img} style={{ width: '85px', height: '85px', borderRadius: 10, marginBottom: '10px' }} alt={`Game ${index}`} />
                        </div>
                      ))}
                    </div>
                  </div>
                  {!isMobileView && filteredSpribe.length > 5 && (
                    <div className="text-center">
                      <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreSP}>
                        <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreSP ? 'Show Less' : 'Show More'}
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card> */}


              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">PRAGMATIC LIVE</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayGamesP.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                  <img src={game.banner} style={{ width: '135px', height: '155px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filteredGamesPragmatic.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreP}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreP ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>  */}
              {/* 
              <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
        <Card.Body className={this.state.padding}>
          <h4 className="mt-2 mb-2 section-title">PRAGMATIC</h4>
          <div className="d-flex w-full" style={{ overflow: 'hidden' }}>
            <div style={{
              display: 'flex',
              flexWrap: isMobileView ? 'nowrap' : 'wrap',
              width: '100%',
              overflowX: isMobileView ? 'scroll' : 'hidden', // Enable horizontal scroll for mobile view
              WebkitOverflowScrolling: 'touch', // for smooth scrolling on mobile
              msOverflowStyle: 'none', // hide scrollbar in IE and Edge
              scrollbarWidth: 'none', // hide scrollbar in Firefox
            }}>
              {displayGamesPL.map((game, index) => (
                <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                  <img src={game.banner} style={{ width: '150px', height: '175px', borderRadius: 10, marginRight: index % 5 === 4 ? '0' : isMobileView ? '10px' : '25px', marginLeft: 3 }} alt={`Game ${index}`} />
                </div>
              ))}
            </div>
          </div>
          {!isMobileView && filtergamespragamaticlive.length > 5 && (
            <div className="text-center">
              <div className="btn btn-more btn-sm px-4 mt-1 mb-3" onClick={this.handleShowMoreO}>
                <i className="mdi mdi-arrow-right-bold-box align-middle" /> {showMoreO ? 'Show Less' : 'Show More'}
              </div>
            </div>
          )}
        </Card.Body>
      </Card> */}

              {/* <Card className="brd7" id="#live" style={{ marginTop: 0, marginBottom: 10, width: '100%', display: 'block' }}>
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title">SLOTS</h4>

                  Evoplay Games
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px', marginBottom: '3px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesEvoplay.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>

                  Genesis Games
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px', marginBottom: '3px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesGenisis.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>

                  Pgsoft Games
                  <div className="d-flex w-full" style={{ overflow: 'hidden', height: '130px' }}>
                    <div style={{ display: 'flex', width: '100%', overflowY: 'auto', scrollbarColor: 'transparent transparent', scrollbarWidth: 'none' }}>
                      {filteredGamesPgsoft.map((game) => (
                        <div key={game.game_code} onClick={() => this.handleGameLaunch(game.game_code, game.provider_code)}>
                          <img src={game.banner} style={{ width: '95px', height: '120px', borderRadius: 10, marginRight: 10, marginLeft: 3 }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </Card.Body>
              </Card>  

           
             
      
      {/* <Card className="brd7" id="originals">
                <Card.Body className={this.state.padding + ' pb-3'}>
                  <h4 className="mt-2 mb-2 section-title"> {t('originals')} Games</h4>
                  <Games t={t} />
                </Card.Body>
              </Card>
              <Card className="brd7">
                <Card.Body className={this.state.padding}>
                  <h4 className="mt-2 mb-2 section-title"> Free Slots</h4>
                  <Slots home={true} />
                  <div className="text-center">
                    <Link className="btn btn-more btn-sm px-4 mt-1 mb-3" to="/slots">
                      <i className="mdi mdi-arrow-right-bold-box align-middle" /> Show More
                    </Link>
                  </div>
                </Card.Body>
              </Card> */}
            </div>
          </>
        }


        <CasinoLeaderboard />

        {/* Loader */}
        {loading && (
          <div className="d-flex justify-content-center align-items-center" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}

        {/* Snackbar */}
        {message && (
          <Alert variant={message.type === 'error' ? 'danger' : 'success'} style={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)', zIndex: 9999 }}>
            {message.text}
          </Alert>
        )}
      </>
    );
  }
}

export default withCookies(Home);
