// import React, { useState, useEffect, useRef } from 'react';
// import styled from 'styled-components';
// import storage from "../../../Storage";
// import { ChevronLeft, ChevronRight, X } from 'lucide-react';
// import EngineCarousel from './VipModalCraousel';
// import socket from '../../../Socket';
// import { decode, encode, getUID } from '../../../Helper';
// import C from '../../../../src/Constant'
// import { getVipLevelDetails } from './calculateLevel';







// const Container = styled.div`
//   color: white;
//   padding: 20px;
//   width: 100%;
//   max-width: 1400px;
//   margin: 0 auto;

//   @media (max-width: 768px) {
//     padding: 10px;
//   }
// `;

// const CardContainer = styled.div`
//   position: relative;
//   width: 100%;
//   min-height: 8rem;
//   border-radius: 8px;
//   margin-bottom: 2rem;
// `;

// const BackgroundImage = styled.img`
//   width: 100%;
//   height: auto;
//   object-fit: cover;
//   border-radius: 8px;
// `;

// const HeroContent = styled.div`
//   position: absolute;
//   top: 50%;
//   right: 0;
//   transform: translateY(-50%);
//   padding: 20px;
//   max-width: 600px;

//   @media (max-width: 1024px) {
//     position: relative;
//     transform: none;
//     max-width: 100%;
//     padding: 20px 0;
//   }

//   h1 {
//     font-size: clamp(24px, 4vw, 32px);
//     font-weight: bold;
//     margin-bottom: 1rem;
//   }

//   p {
//     font-size: clamp(14px, 2vw, 16px);
//     line-height: 1.5;
//   }
// `;

// const MainWrapper = styled.div`
//   display: flex;
//   gap: 20px;
//   align-items: center;
//   width: 100%;
//   // margin-top: 20px;

//   @media (max-width: 1024px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;

// const CarouselContainerVip = styled.div`
//   width: 300px;
//   min-width: 300px;
//   background-color: #1a1a1a;
//   border-radius: 10px;

//   @media (max-width: 1024px) {
//     width: 100%;
//     max-width: 400px;
//   }
// `;

// const CardVip = styled.div`
//   color: white;
//   border-radius: 10px;
//   padding: 20px;
//   width: 100%;
//   position: relative;
//   cursor: pointer;

//   img {
//     width: 100%;
//     height: auto;
//     object-fit: contain;
//   }
// `;

// const InfoContainer = styled.div`
//   flex: 1;
//   width: 100%;
// `;

// const InfoHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   margin-bottom: 20px;
//   flex-wrap: wrap;
//   gap: 20px;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     text-align: center;
//   }
// `;

// const UserProfile = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   max-width: 200px;
//   margin: 0 auto;

//   img.avatar-panda {
//     width: 80px;
//     height: 80px;
//     border-radius: 50%;
//     margin-bottom: 10px;
//   }

//   .username {
//     font-size: 18px;
//     font-weight: bold;
//     margin: 10px 0;
//   }
// `;

// const ProgressBarContainer = styled.div`
//   width: 100%;
//   background-color: #1e2124;
//   padding: 20px;
//   border-radius: 8px;
//   margin-top: 20px;
// `;

// const NavigationButton = styled.button`
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   background: rgba(0, 0, 0, 0.7);
//   border: none;
//   color: white;
//   width: 32px;
//   height: 32px;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   z-index: 2;

//   &:hover {
//     background: rgba(0, 0, 0, 0.9);
//   }

//   @media (max-width: 768px) {
//     width: 28px;
//     height: 28px;
//   }
// `;

// const LeftButton = styled(NavigationButton)`
//   left: 10px;
// `;

// const RightButton = styled(NavigationButton)`
//   right: 10px;
// `;



// const Title = styled.h2`
//   font-size: 1.5rem;
//   font-weight: bold;
//   margin-bottom: 20px;
// `;

// const CarouselContainer = styled.div`
//   overflow-x: auto;
//   scroll-snap-type: x mandatory;
//   scrollbar-width: none;
//   -ms-overflow-style: none;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// `;

// const CarouselTrack = styled.div`
//   display: flex;
//   gap: 20px;
//   padding-bottom: 20px;
// `;

// const Card = styled.div`
//   flex: 0 0 100%;
//   scroll-snap-align: start;
//   background-color: rgb(32,34,37);
//   border-radius: 8px;
//   padding: 20px;
//   text-align: center;
//   position: relative;
  
//   @media (min-width: 600px) and (max-width: 767px) {
//     flex: 0 0 calc(33.333% - 13.333px);
//   }
  
//   @media (min-width: 768px) and (max-width: 1023px) {
//     flex: 0 0 calc(33.333% - 13.333px);
//   }
  
//   @media (min-width: 1024px) {
//     flex: 0 0 calc(20% - 16px);
//   }
// `;

// const CardTitle = styled.h3`
//   font-size: 16px;
//   margin-bottom: 10px;
//   text-wrap: nowrap;
// `;

// const CardDescription = styled.p`
//   font-size: 14px;
//   color: #aaa;
// `;

// const Badge = styled.span`
//   background-color: #4a4a4a;
//   color: white;
//   padding: 2px 6px;
//   border-radius: 4px;
//   font-size: 11px;
//   position: absolute;
//   top: 10px;
//   right: 10px;
// `;





// const BenefitsGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 20px;

//   @media (max-width: 768px) {
//     grid-template-columns: 1fr;
//   }
// `;

// const BenefitCard = styled.div`
//   background-color: #2a2a2a;
//   border-radius: 10px;
//   padding: 20px;
//   text-align: center;
// `;

// const BenefitImage = styled.img`
//   width: 100%;
//   object-fit: cover;
//   margin-bottom: 15px;
// `;


// const BenefitTitle = styled.h2`
//   color: white;
//   margin-bottom: 10px;
//   font-size: 16px;
// `;

// const BenefitDescription = styled.p`
//   color: #bbb;
//   font-size: 12px;
// `;







// const ProgressBar = styled.div`
//   width: 100%;
//   height: 8px;
//   background-color: #2f3136;
//   border-radius: 4px;
//   overflow: hidden;
// `;

// const Progress = styled.div`
//   width: ${props => props.progress}%;
//   height: 100%;
//   background-color: #5865f2;
//   border-radius: 4px;
// `;

// const LevelIndicator = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 8px;
// `;

// const LevelCircle = styled.div`
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   background-color: #5865f2;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   font-weight: bold;
//   font-size: 12px;
//   margin-right: 8px;
//   text-transform:no-wrap;
// `;

// const LevelText = styled.span`
//   color: #72767d;
//   font-size: 14px;
// `;

// const StatsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 8px;
//   color: #72767d;
//   font-size: 12px;
// `;





// const FlexContainerStar = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 5px;
//   justify-content: center;
// `;

// const CustomModal = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// `;

// const ModalContent = styled.div`
//   background-color: #17181B;
//   color: white;
//   width: 900px;
//   border-radius: 8px;
//   padding: 20px;
// `;

// const ModalHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;
// `;

// const ModalTitle = styled.div`
//   margin: 0;
//   font-size:18px;
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
// `;

// const ModalBody = styled.div`
//   max-height: 80vh;
//   overflow-y: auto;
// `;

// const cardData = [
//   { 
//     title: 'Bronze Card', 
//     description: 'Smart Player', 
//     subtitle: 'You deserve the best',
//     imageSrc: '/assets/images/vip-new/vip-1.webp'
//   },
//   { 
//     title: 'Silver Card', 
//     description: 'VIP Player', 
//     subtitle: 'Enjoy exclusive benefits',
//     imageSrc: '/assets/images/vip-new/vip-3.webp'
//   },
//   { 
//     title: 'Gold Card', 
//     description: 'Elite Player', 
//     subtitle: 'Experience luxury gaming',
//     imageSrc: '/assets/images/vip-new/vip-4.png'
//   },
//   { 
//     title: 'Platinum Card', 
//     description: 'Elite Player', 
//     subtitle: 'Experience luxury gaming',
//     imageSrc: '/assets/images/vip-new/vip-5.webp'
//   },
//   { 
//     title: 'Diamond Card', 
//     description: 'Elite Player', 
//     subtitle: 'Experience luxury gaming',
//     imageSrc: '/assets/images/vip-new/vip-2.webp'
//   },
// ];

// const cards = [
//   {
//     title: 'Roll Competition',
//     description: 'Try your luck once a day for a spot on our daily top 10 and win some free Doge!',
//     badge: 'V03',
//     img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
//   },
//   {
//     title: 'Tip',
//     description: 'Send a little (or a lot of) luck to your friends, you\'ve earned the ability to tip others.',
//     badge: 'V04',
//     img: 'https://static.nanogames.io/assets/money.9316ba83.png'
//   },
//   {
//     title: 'Secret Treasure',
//     description: 'The higher rank you are, the more surprising it will be',
//     badge: 'V04',
//     img: 'https://static.nanogames.io/assets/secret.5ee36c98.png'
//   },
//   {
//     title: 'Private chat',
//     description: 'Send messages and chat with your closest friends in private on-site',
//     badge: 'V04',
//     img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
//   },
//   {
//     title: 'Raining',
//     description: 'Randomly reward players in chat room every 6 hours.',
//     badge: 'V04',
//     img: 'https://static.nanogames.io/assets/rain.81aa4c59.png'
//   },
//   {
//     title: 'Coindrop',
//     description: 'You can now get coin drops from the chatroom for a little extra luck!',
//     badge: 'V07',
//     img: 'https://static.nanogames.io/assets/coindrop.2d362bea.png'
//   },
//   {
//     title: 'VIP Lounge',
//     description: 'Access exclusive VIP areas with special promotions and bonuses.',
//     badge: 'V08',
//     img: 'https://static.nanogames.io/assets/rain.81aa4c59.png'
//   },
//   {
//     title: 'Priority Support',
//     description: 'Get faster responses from our dedicated VIP support team.',
//     badge: 'V09',
//     img: 'https://static.nanogames.io/assets/dice.cb7509e5.png'
//   },
// ];

// const benefits = [
//   {
//     title: "Unique VIP Transfer Program",
//     description: "99exchange.red respects and welcomes every distinguished VIP and will give you an exclusive VIP status that you can continue your honor here. Contact us for details.",
//     imagePlaceholder: "https://static.nanogames.io/assets/emoji_m.ae3b136b.png"
//   },
//   {
//     title: "Exclusive VIP Host",
//     description: "There will be exclusive host for every VIP. Feel free to contact them any time.",
//     imagePlaceholder: "https://static.nanogames.io/assets/wallet_m.747f1dcc.png"
//   },
//   {
//     title: "The SVIP Code",
//     description: "Only SVIP can get code from exclusive host. Become an SVIP, rock like a superstar and have the spotlight shining on you!",
//     imagePlaceholder: "https://static.nanogames.io/assets/start_m.7bbee082.png"
//   }
// ];

// const VipClub = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [showModal, setShowModal] = useState(false);
//   const [showVipLevelModal, setShowVipLevelModal] = useState(false);
//   const [vipDetails, setVipDetails] = useState(null);
//   const [progress, setProgress] = useState(0);
//   const carouselRef = useRef(null);
//   const progressAnimationRef = useRef(null);

//   const [wageredAmount, setWagredAmount] = useState(null);

//   const animateProgress = (targetProgress) => {
//     let currentProgress = 0;
//     const duration = 1000; // 1 second animation
//     const startTime = performance.now();

//     const animate = (currentTime) => {
//       const elapsed = currentTime - startTime;
//       const progress = Math.min(elapsed / duration, 1);
      
//       currentProgress = progress * targetProgress;
//       setProgress(currentProgress);

//       if (progress < 1) {
//         progressAnimationRef.current = requestAnimationFrame(animate);
//       }
//     };

//     progressAnimationRef.current = requestAnimationFrame(animate);
//   };
//   function parseWageredAmount(amountString) {
//     if (!amountString) return 0; // Handle null or undefined data
//     let cleanedAmount = amountString.replace(/,/g, ''); // Remove any commas
//     let parsedAmount = parseFloat(cleanedAmount); // Convert to float
//     return isNaN(parsedAmount) ? 0 : parsedAmount; // Return 0 if parsing fails
// }

//   const getUserInfo = (data) => {
//     if (data.status) {
//       console.log("data comes", data);

//       let waggerAmount =parseWageredAmount(data?.profit).toFixed(4);
//       console.log("wagamnounbt",wageredAmount)
//       if (waggerAmount) {
//         setWagredAmount(waggerAmount);
//         const vipDetails = getVipLevelDetails(waggerAmount);
//         console.log("vippp",vipDetails);
//         setVipDetails(vipDetails);
//         // Animate progress based on completion percentage
//         animateProgress(parseFloat(vipDetails.completionPercentage));
//       }
//     } else {
//       console.log("called");
//     }
//   };

//   useEffect(() => {
//     socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
//     socket.emit(C.USER_INFO, encode({ 
//       id: getUID, 
//       coin: storage.getKey('coin') ? storage.getKey('coin') : '', 
//       rate: null, 
//       game: 'all', 
//       first: true 
//     }));

//     // Cleanup animation on unmount
//     return () => {
//       if (progressAnimationRef.current) {
//         cancelAnimationFrame(progressAnimationRef.current);
//       }
//     };
//   }, []);

  
//   useEffect(()=>{
    
//     socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
//     socket.emit(C.USER_INFO, encode({ id: getUID, coin: storage.getKey('coin') ? storage.getKey('coin'):'', rate: null, game: 'all', first: true }));

//   },[])

//   useEffect(() => {
//     const username = storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest';
//     console.log(username, "useee");
//   }, []);

//   const handlePrev = (e) => {
//     e.stopPropagation();
//     setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardData.length - 1));
//   };

//   const handleNext = (e) => {
//     e.stopPropagation();
//     setActiveIndex((prevIndex) => (prevIndex < cardData.length - 1 ? prevIndex + 1 : 0));
//   };

//   const handleCardClick = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleVipLevelClick = () => {
//     setShowVipLevelModal(true);
//   };

//   const handleCloseVipLevelModal = () => {
//     setShowVipLevelModal(false);
//   };

//   const activeCard = cardData[activeIndex];

//   return (
//     <Container>
//       <CardContainer>
//         <BackgroundImage src="/assets/images/bg_m.png" alt="VIP background" />
//         <HeroContent>
//           <h1>VIP-CLUB</h1>
//           <h1>Exclusive Player Benefits</h1>
//           <p>
//             As a way of showing our gratitude and appreciation to our VIP players, NANOGAMES.IO invites you to join our VIP Club where there is an abundance of gifts, giveaways, higher cash back and unique features. Don't miss out on all the exciting and amazing fun!
//           </p>
//         </HeroContent>
//       </CardContainer>

//       <MainWrapper>
//         <CarouselContainerVip>
//           <CardVip>
//             <img 
//               onClick={handleCardClick} 
//               src={activeCard.imageSrc} 
//               alt="VIP Card"
//             />
//             <LeftButton onClick={handlePrev}>
//               <ChevronLeft size={20} />
//             </LeftButton>
//             <RightButton onClick={handleNext}>
//               <ChevronRight size={20} />
//             </RightButton>
//           </CardVip>
//         </CarouselContainerVip>

//         <InfoContainer>
//           <InfoHeader>
//             <UserProfile>
//               <img 
//                 className="avatar-panda" 
//                 src="https://img2.nanogames.io/avatar/589929/s?t=1727260678731" 
//                 alt="Avatar" 
//               />
//               <div className="username">{localStorage.getItem("name")}</div>
//               <FlexContainerStar>
//                 {[...Array(4)].map((_, index) => (
//                   <img 
//                     key={index} 
//                     style={{width: '20px'}} 
//                     className="img-star" 
//                     alt="level-star" 
//                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg==" 
//                   />
//                 ))}
//               </FlexContainerStar>
//             </UserProfile>
//             <div 
//               style={{color: '#49BC0D', fontSize: '18px', fontWeight: 'bold', cursor: 'pointer'}} 
//               onClick={handleVipLevelClick}
//             >
//               About VIP Level
//             </div>
//           </InfoHeader>

//           <ProgressBarContainer>
//             <LevelIndicator>
//               <LevelCircle>{vipDetails?.previousVipLevel?.replace('IP', '').replace(' ', '') || 'V0'}</LevelCircle>
//               <LevelText>{vipDetails?.vipLevel || 'V01'}</LevelText>
//             </LevelIndicator>
//             <ProgressBar>
//               <Progress progress={progress} />
//             </ProgressBar>
//             <StatsContainer>
//               <span>
//                 Your current XP: <span style={{color:'white'}}>{vipDetails?.currentWagger || '0'} XP</span> / 
//                 Total wager: <span style={{color:'white'}}>{vipDetails?.currentWagger || '0.00'} USD</span>
//               </span>
//               <span>
//                 Reach <span style={{color:'white'}}>{vipDetails?.waggerNeededForNextLevel || '1'} XP</span> to{' '}
//                 <span>{vipDetails?.vipLevel || 'VIP1'}</span>
//               </span>
//             </StatsContainer>
//           </ProgressBarContainer>
//         </InfoContainer>
//       </MainWrapper>

//       <Title>VIP & SVIP Unlocked Rights</Title>
//       <CarouselContainer ref={carouselRef}>
//         <CarouselTrack>
//           {cards.map((card, index) => (
//             <Card key={index}>
//               <Badge>{card.badge}</Badge>
//               <img src={card.img} style={{height:'6.2rem', objectFit:'contain'}} alt={index}/>
//               <CardTitle>{card.title}</CardTitle>
//               <CardDescription>{card.description}</CardDescription>
//             </Card>
//           ))}
//         </CarouselTrack>
//       </CarouselContainer>

//       <Title>Exclusive VIP & SVIP Benefits</Title>
//       <BenefitsGrid>
//         {benefits.map((benefit, index) => (
//           <BenefitCard key={index}>
//             <BenefitImage src={benefit.imagePlaceholder} alt={benefit.title} />
//             <BenefitTitle>{benefit.title}</BenefitTitle>
//             <BenefitDescription>{benefit.description}</BenefitDescription>
//           </BenefitCard>
//         ))}
//       </BenefitsGrid>

//       {showModal && (
//         <CustomModal>
//           <ModalContent>
//             <ModalHeader>
//               <ModalTitle>About VIP Cards</ModalTitle>
//               <CloseButton onClick={handleCloseModal}>
//                 <X size={20} />
//               </CloseButton>
//             </ModalHeader>
//             <ModalBody>
//               <img src={activeCard.imageSrc} style={{height:'170px', width:'292px', objectFit:'contain'}} alt="VIP Card"/>
//               <h3>{activeCard.title}</h3>
//               <p>{activeCard.description}</p>
//               <p>{activeCard.subtitle}</p>
//             </ModalBody>
//           </ModalContent>
//         </CustomModal>
//       )}

//       {showVipLevelModal && (
//         <CustomModal>
//           <ModalContent>
//             <ModalHeader>
//               <ModalTitle>About VIP Level</ModalTitle>
//               <CloseButton onClick={handleCloseVipLevelModal}>
//                 <X size={20} />
//               </CloseButton>
//             </ModalHeader>
//             <ModalBody>
//               <EngineCarousel/>
            
//             </ModalBody>
//           </ModalContent>
//         </CustomModal>
//       )}
//     </Container>
//   );
// };

// export default VipClub;

import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import VIPClubModal from './LoggedInVip';

// Styled Components

const Container = styled.div`
  // padding: 20px;
`;


const Card = styled.div`
  background: linear-gradient(87deg, rgba(255, 130, 15, 0) 1.53%, rgba(255, 130, 15, .4) 98.57%);
  border-radius: 12px;
  padding: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto;
  min-height: 300px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 20px;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 20px 15px;
  }
`;

const Content = styled.div`
  max-width: 50%;
  z-index: 2;
  position: relative;

  @media (max-width: 968px) {
    max-width: 60%;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  line-height: 1.2;
    font-family:normal;
  text-transform: uppercase;

  @media (max-width: 968px) {
    font-size: 2.2rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #cccccc;
  margin-bottom: 30px;
  font-weight: 300;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const SignUpButton = styled.div`
  background: linear-gradient(90deg, #ffd700 0%, #ffb700 100%);
  color: black !important;
  text-align:center;
  padding: 12px 40px;
  width:300px;
  border-radius: 15px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: 480px) {
    padding: 10px 30px;
    font-size: 1rem;
  }
`;

const RadialBackground = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 600px;
  height: 600px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;

// New styled components for the image section
const ImageContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  width: 45%;
  // max-width: 500px;
  // height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 968px) {
    width: 40%;
    right: 20px;
  }

  @media (max-width: 768px) {
    position: relative;
    width: 80%;
    right: auto;
    top: auto;
    transform: none;
    margin: 20px auto;
    height: 200px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
`;

// Stars decoration
const Star = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: #FFD700;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: twinkle 1.5s infinite alternate;
  
  &:nth-child(1) { top: 20%; right: 15%; }
  &:nth-child(2) { top: 30%; right: 35%; }
  &:nth-child(3) { top: 60%; right: 25%; }
  
  @keyframes twinkle {
    from { opacity: 0.4; }
    to { opacity: 1; }
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  column-gap: 20px;
  row-gap: 60px; /* Single larger gap between rows */
  padding: 40px 20px;
`;

const CardB = styled.div`
  background: #323738;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  text-align: center;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ImageContainerB = styled.div`
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TitleB = styled.h3`
  color: white;
  font-size: 24px;
  margin: 40px 0 10px;
  font-weight: 600;
  font-family:normal;
`;

const DescriptionB = styled.p`
  color: #9FA6B2;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`;

const VipClub = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

 useLayoutEffect(() => {
    // Check localStorage on component mount
    const loginStatus = localStorage.getItem('logged');
    setIsLoggedIn(loginStatus === 'true');

    // Optional: Add event listener for storage changes
    const handleStorageChange = () => {
      const updatedLoginStatus = localStorage.getItem('logged');
      setIsLoggedIn(updatedLoginStatus === 'true');
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);


  console.log("islogged in", isLoggedIn)


  const features = [
    {
      title: 'No-Fee Withdrawal',
      description: 'Reach VIP 38 for no-fee cryptocurrency withdrawals.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-withdraw-95b168e8.png'
    },
    {
      title: 'Rewarding Lucky Spin',
      description: 'Spin the wheel & receive bigger prizes as you level up.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-spin-ab922353.png'
    },
    {
      title: 'Dedicated VIP Host',
      description: 'Selected VIPs get personalized attention from a dedicated host.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-host-a54c0031.png'
    },
    {
      title: 'Amazing Level Up Bonus',
      description: 'Unlock multiple surprise rewards as you level up.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-chat-291a0bc7.png'
    },
    {
      title: 'Fun Tips & Raining',
      description: 'Tip to show appreciation or try Coin Drops to win rewards.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-bonus-47d6d466.png'
    },
    {
      title: 'Exclusive Cashback',
      description: 'Earn incredible cashback rewards every week & month.',
      image: 'https://hash.game/modules/bonus2/assets/card-img-cashback-1dfa2276.png'
    },
    
  ];

  return (

    <>
 {
  isLoggedIn ? (<VIPClubModal/>):(  <Container>
    <Card>
      <Content>
        <Title>Exclusive VIP Benefits</Title>
        <Description>
          Join our VIP Club now and get ready to be showered with gifts, giveaways, 
          and amazing features. Experience the thrill of higher cash back and exclusive 
          rewards that are sure to leave you amazed.
        </Description>
        <SignUpButton>Sign Up</SignUpButton>
      </Content>
      <RadialBackground />
      <ImageContainer>
        <Star />
        <Star />
        <Star />
        <StyledImage 
          src="https://hash.game/modules/bonus2/assets/vip-benefit-hero-0ac174b8.png" 
          alt="VIP Benefits illustration" 
        />
      </ImageContainer>
    </Card>
    
    <CardContainer>
      {features.map((feature, index) => (
        <CardB key={index}>
          <ImageContainerB>
            <CardImage src={feature.image} alt={feature.title} />
          </ImageContainerB>
          <TitleB>{feature.title}</TitleB>
          <DescriptionB>{feature.description}</DescriptionB>
        </CardB>
      ))}
    </CardContainer>
  </Container>)
 }
    </>

  );
};

export default VipClub;