import React from 'react';
import styled from 'styled-components';

const PromotionContainer = styled.div`
 background:linear-gradient(111.21deg, rgb(210, 236, 39) -88.74%, rgb(50, 55, 56) 46.65%);;
 color: #fff;
 padding: 20px 30px;
 border-radius: 8px;
 display:flex;
 align-items:center;
 justify-content:space-between;
`;

const Title = styled.div`
 font-size: 20px;
font-weight:bold;
`;

const BonusContainer = styled.div`
 display: flex;
 justify-content: space-between;

`;

const BonusCard = styled.div`
  background-image: url(${(props) => props.backgroundImage}) !important;
  // border-radius: 8px;
  padding: 1rem;
      background-size: 100% 100%;
  text-align: center;
  position: relative;
`;
const BonusImage = styled.img`
  width: 128px;
  height: 125px;
  margin-bottom: 1rem;
    margin-top: 0.5rem;
`;
const BonusImage1 = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: 1rem;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const CenteredText = styled.div`
  color: white;
  font-size: 0.75rem;
  text-align: center;
  margin-top:4px;
`;


const BonusTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
`;
const BonusPercentage = styled.div`
 font-size: 18px;
 font-weight: bold;
 color: #fff;
`;

const ButtonContainer = styled.div`
 background-color: #323738;
 display:flex;
 justify-content:center;
 align-items:center;
 padding:6px 4px;
   border-radius: 8px;
`;

const Badge = styled.div`
 position: absolute;
 top: -10px;
 right: -10px;
 background-color: ${(props) => props.color};
 color: #fff;
 padding: 0.5rem 1rem;
 border-radius: 4px;
 font-size: 0.8rem;
 font-weight: bold;
`;


const DepositNowButton = styled.div`
 background: linear-gradient(90deg, rgb(36, 238, 137), rgb(159, 232, 113));
  color: black;
  border-radius: 8px;
  border:none;
  font-weight:bold !important;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #00A876;
  }
`;

const Promotion = () => {
 return (
   <PromotionContainer>
    <div style={{width:"300px"}}>
    <Title>Great Bonus For Every</Title>
    <Title>Deposit</Title>
    <div style={{fontSize:"36px", fontWeight:"bold"}}>UP TO 360% </div>
    <DepositNowButton>Deposit Now</DepositNowButton>
    </div>
     <div>
     <BonusContainer>
     <div style={{ display: 'flex', justifyContent: 'space-between', gap:'10px', }}>
       <BonusCard color1="#ffc107" color2="#ff9800"   backgroundImage="https://hash.game/modules/bonus2/assets/d-1-b26450d0.png"
       >
          <BonusImage1 src="https://hash.game/modules/bonus2/assets/step-i-f-fc26a955.png">
        <CenteredText>01</CenteredText>
      </BonusImage1>
         <BonusImage src="https://hash.game/modules/bonus2/assets/d-1-box-43867056.png" alt="Gift Box 1" />
         {/* <BonusTitle>First Deposit</BonusTitle> */}
         <BonusPercentage>180% BONUS</BonusPercentage>
         {/* <Badge color="#ffc107">01</Badge> */}
         <ButtonContainer>First Deposit</ButtonContainer>
       </BonusCard>
       <BonusCard color1="#28a745" color2="#4CAF50"   backgroundImage="https://hash.game/modules/bonus2/assets/d-2-18e324d3.png">
 
       <BonusImage1 src="https://hash.game/modules/bonus2/assets/step-i-s-c36b403b.png">
        <CenteredText>02</CenteredText>
      </BonusImage1>
         <BonusImage src="https://hash.game/modules/bonus2/assets/d-2-box-0395581a.png" alt="Gift Box 2" />
         {/* <BonusTitle>Second Deposit</BonusTitle> */}
         <BonusPercentage>240% BONUS</BonusPercentage>
         {/* <Badge color="#28a745">02</Badge> */}
         <ButtonContainer>Second Deposit</ButtonContainer>

       </BonusCard>
       <BonusCard color1="#dc3545" color2="#e64545" backgroundImage="https://hash.game/modules/bonus2/assets/d-3-63590e37.png">
       {/* <BonusImage1 src="https://hash.game/modules/bonus2/assets/step-i-t-4b3271c5.png" alt="Gift11" /> */}
       <BonusImage1 src="https://hash.game/modules/bonus2/assets/step-i-t-4b3271c5.png">
        <CenteredText>03</CenteredText>
      </BonusImage1>
         <BonusImage src="https://hash.game/modules/bonus2/assets/d-3-box-c15095d8.png" alt="Gift Box 3" />
         {/* <BonusTitle>Third Deposit</BonusTitle> */}
         <BonusPercentage>300% BONUS</BonusPercentage>
         {/* <Badge color="#dc3545">03</Badge> */}
         <ButtonContainer>Third Deposit</ButtonContainer>
       </BonusCard>
       <BonusCard color1="#6f42c1" color2="#8a3ab9" backgroundImage="https://hash.game/modules/bonus2/assets/d-4-d6bc6dc3.png">
       <BonusImage1 src="https://hash.game/modules/bonus2/assets/step-i-t-4b3271c5.png">
        <CenteredText>04</CenteredText>
      </BonusImage1>
         <BonusImage src="https://hash.game/modules/bonus2/assets/d-4-box-f5e64f9d.png" alt="Gift Box 4" />
         {/* <BonusTitle>Fourth Deposit</BonusTitle> */}
         <BonusPercentage>360% BONUS</BonusPercentage>
         {/* <Badge color="#6f42c1">04</Badge> */}
         <ButtonContainer>Fourth Deposit</ButtonContainer>
       </BonusCard>
     </div>
     </BonusContainer>
    
     </div>
   </PromotionContainer>
 );
};

export default Promotion;