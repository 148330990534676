import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronDown, ChevronRight } from 'lucide-react';

const AccordionModal = styled.div`
background-color:#232626;
padding-bottom:12px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color:#323738;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
`;

const HeaderTitle = styled.h2`
  color: white;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 600;
`;

const AccordionItem = styled.div`
  margin: 10px 24px;
  border-radius: 18px;
  overflow: hidden;
  background: #323738;
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:14px 14px 14px 0px;
  cursor: pointer;
   background: #323738;
`;

const LevelInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LevelFlag = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color};
  border-radius: 2px;
`;
const LevelFlagImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

const LevelTitle = styled.span`
  color: white;
  font-size: 14px;
`;

const LevelRange = styled.span`
  color: white;
  font-size: 14px;
  margin-left: 8px;
`;

const AccordionContent = styled.div`
  max-height: ${props => props.isOpen ? '1000px' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
 background: #323738;
`;

const LevelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  &:last-child {
    border-bottom: none;
  }
`;

const LevelIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;

const RequiredXP = styled.span`
  color: white;
  font-size: 14px;
`;

const VIPLevelAccordion = ({ setShowLevelSystem, showLevelSystem }) => {
    const [openLevel, setOpenLevel] = useState('');

    const levels = [
        {
            title: 'Bronze',
            range: 'VIP 2-7',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAElBMVEUAAAC6fVu6el26fVu7flu6fFsuuEvsAAAABXRSTlMAnF7UJIqIv90AAABvSURBVDjLpdRLDcBACEXRJ4E6IFWABCyUAf9W6mDugrc+ISF8hHnvcc09TSAIOIBPAJqAATgCUASMQAIoAQgCDuATgCZgAI4AFAEjkOsKrW0X4wRC21lMrvehCBytt7q1vaxxAqHtf5hc/6jWc4/963N/yWp86usAAAAASUVORK5CYII=',
            badgeImage: '/assets/vip/badge-bronze.png',
            subLevels: [
                { level: 'VIP 02', xp: '100', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' },
                { level: 'VIP 03', xp: '200', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' },
                { level: 'VIP 04', xp: '1000', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' },
                { level: 'VIP 05', xp: '2000', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' },
                { level: 'VIP 06', xp: '3000', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' },
                { level: 'VIP 07', xp: '4000', img: 'https://hash.game/modules/bonus2/assets/small-badge-bronze-48e882bb.png' }
            ]
        },
        {
            title: 'Silver',
            range: 'VIP 8-21',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAG1BMVEUAAADi4uLf39/f39/i4uLh4eHi4uLh4eHh4eHIfvzxAAAACHRSTlMAv0AaaN+Y77W4ur0AAAB8SURBVDjLhdRBEYQwEETRkcDWGqBWwUiIBKowEA8oAEKI7HWQ98/v1IcO9pn3jTHvFqgATwK0ANgEEqAHwC5QAJ4AuAUqwJMALQA2gQToAbALFIFVYBFoAm9qhypwCZwCowgcAl1grAKLQBN4E2BUgUvgFBhF4PDL/eblH057YqEIBjcRAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-silver.png',
            subLevels: [
                { level: 'VIP 08', xp: '5000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 09', xp: '7000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 10', xp: '9000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 11', xp: '11000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 12', xp: '13000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 13', xp: '15000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 14', xp: '17000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 15', xp: '19000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 16', xp: '21000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 17', xp: '23000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 18', xp: '25000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 19', xp: '27000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 20', xp: '29000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' },
                { level: 'VIP 21', xp: '45000', img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAA4VBMVEUAAACtra2ysrL09PS6urr+/v7s7Oy9vb2oqKj5+fmqqqr09PTMzMyHh4eTk5PExMSUlJTW1tatra309PTHx8eurq6CgoLMzMydnZ2IiIjAwMCPj4/y8vKenp69vb2hoaHS0tLs7Ozc3NzDw8OCgoKYmJitra2kpKRgYGC9vb2SkpJMTExnZ2efn5+6urqcnJyPj4+zs7OJiYlUVFTl5eXY2Ni4uLi1tbWMjIzd3d3Hx8fw8PDr6+v29vaGhoZaWlqhoaGnp6dubm7T09PPz8/Gxsbh4eHLy8v8/Px7e3t1dXW1ReG6AAAAI3RSTlMAIL/fEN/fv4PfoiBvWDTv5d/fv59A39e3t6+Yf3Dv77+fUCy4tUQAAAKpSURBVDjLhdRpc6owFAZgrba1+773riHBUAmEsm8F2dT//4PuCXIr0c70/eDgyeN7MuPoQGS4d3i4J3J4OvjMaTsSJ+eb4Z6LPM91iXcx/j8aPnjcdT0PuceDTe4Ipa5LKaGflbeUMMrEbDjo5dhFjFKovejGQ+RxRClDXr8Q5oQiihAi7LYrZAQhGMmFkImHICHj6G58+nc8RNwLYSAK5YyhsgqbhrAHpyiufjMeNiGqWFsoV3IUhk1ZFU4BcRxUNiD5RFZtJQMXBI7jlCW8BEHZhOxaFO5WBnYAsoG7FvBQO4j/kEhX+bO26zpoUFVVCJVBXdv2xRfu5Ca2bbsQLAxBosKG/DnZrnuN4jh24LwKEeeojQOj6FWiJ6MoiuqQwu0ql+S5SuCRUmTDeNSXN0mSNB6jKHQJyVU10zMO0OUlHNz04P5iwYjHEOKdMzDGLkDCFot9CSYqcTdMOM3UPU7UZAvGcC3Sq9M007dWXp5FEjxa2ALm4PS1M01/OZvxXN+CSZ3ltFu7rlPms9mUq3qcHEkw0FXaW2utZuCmRDVkOEoCQ2VZtxa2zgWbfhAdx8lIgg7WXaOrg61r90GwZkcSjBwNe2uXruYde3/PsbkDTY0LBls37k3V/FqGceGbBIut3VrB3t5U35LhQVykFtGsVb8OkllpHR9I0FFSsuzVgRMwVQIZ2oGi+Lrfq4OsdEtRbLsPr8rSUBQFk+XGTX0TRnpZXvXgM3MZSKDmbL32XcOKcIyx5x4cXquqqvtwYumpqFvitH0D461f7CQzDENTxGk2n2LxmRTD6NdkLDkoPcYQX1Ct/YCPIY9Qt5PzS/FFw8q2V9M0/Hg3+Dpnl6Zp+q2Dh0v5b0Le/+JbXfwX6XK79Clt83Q++C5n94py37Lv6dkXW/8B5oOoEFQoRB8AAAAASUVORK5CYII=' }
            ]
        },
        {
            title: 'Gold',
            range: 'VIP 22-37',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAGFBMVEUAAAD/xCr/wir/xCn/xCj/wyn/win/wynTh4F5AAAAB3RSTlMAoWDkJIBQKKeusgAAAHRJREFUOMuF1EERBCEQBMGRwFk4BTxWABKQgAWChbG/Dsh6Z0T/Otj/3oi89wpUAU3MAHgEOsAKgCNQAFYD2AFQBQbADIBHoAOsADgCRaAJdIFXYArkEKgCWyCbQBE4Aksgu8ArMAVyCFSBLZBNoPjlfvf6B4qIF1G5jrf8AAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-gold.png',
            subLevels: [
                { level: 'VIP 22', xp: '49000', img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 23', xp: '59000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 24', xp: '69000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 25', xp: '79000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 26', xp: '89000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 27', xp: '99000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 28', xp: '109000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 29', xp: '119000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 30', xp: '129000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 31', xp: '139000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 32', xp: '149000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 33', xp: '159000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 34', xp: '169000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 35', xp: '179000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 36', xp: '189000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' },
                { level: 'VIP 37', xp: '297000' ,img:'https://hash.game/modules/bonus2/assets/small-badge-gold-2c53c239.png' }
            ]
        },
        {
            title: 'Platinum I',
            range: 'VIP 38-55',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAFVBMVEUAAACdZfedYvWdY/afZfmdY/WdY/Z/YK1kAAAABnRSTlMAnF7UJO8aXzayAAAAdUlEQVQ4y6XUQQ0AMQhE0ZHAOmhWQSVUxBqgUPxLWAf9B+b8AiEQhHnvGap7ksAkQC1cAD4CBiAEYBMwAGcBcAGYBAYAF4CPgAEIAdgEjMBqV0h1p6hBYKq7i1oADt7DJhAEyggkgSBQg8Ak4ARqtX9U6rnHfsxyynumX3nhAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-platinum1.png',
            subLevels: [
                { level: 'VIP 38', xp: '321000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 39', xp: '377000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 40', xp: '433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 41', xp: '489000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 42', xp: '545000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 43', xp: '601000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 44', xp: '657000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 45', xp: '713000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 46', xp: '769000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 47', xp: '825000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 48', xp: '881000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 49', xp: '937000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 50', xp: '993000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 51', xp: '1049000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 52', xp: '1105000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 53', xp: '1161000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 54', xp: '1217000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'},
                { level: 'VIP 55', xp: '1273000' , img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png'}
            ]
        },
        {
            title: 'Platinum II',
            range: 'VIP 56-69',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAFVBMVEUAAACdZfedYvWdY/afZfmdY/WdY/Z/YK1kAAAABnRSTlMAnF7UJO8aXzayAAAAdUlEQVQ4y6XUQQ0AMQhE0ZHAOmhWQSVUxBqgUPxLWAf9B+b8AiEQhHnvGap7ksAkQC1cAD4CBiAEYBMwAGcBcAGYBAYAF4CPgAEIAdgEjMBqV0h1p6hBYKq7i1oADt7DJhAEyggkgSBQg8Ak4ARqtX9U6rnHfsxyynumX3nhAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-platinum2.png',
            subLevels: [
                { level: 'VIP 56', xp: '2369000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 57', xp: '2657000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 58', xp: '2945000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 59', xp: '3233000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 60', xp: '3521000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 61', xp: '3809000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 62', xp: '4097000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 63', xp: '4385000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 64', xp: '4673000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 65', xp: '4961000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 66', xp: '5249000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 67', xp: '5537000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 68', xp: '5825000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' },
                { level: 'VIP 69', xp: '8577000', img:'https://hash.game/modules/bonus2/assets/small-badge-platinum-786c30ea.png' }
            ]
        },
        {
            title: 'Diamond I',
            range: 'SVIP 1-15',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAFVBMVEUAAAD1UKryUKr0UKn2UKjzUKr0UKkKyqb7AAAABnRSTlMAnF7UJO8aXzayAAAAdUlEQVQ4y6XUQQ0AMQhE0ZHAOmhWQSVUxBqgUPxLWAf9B+b8AiEQhHnvGap7ksAkQC1cAD4CBiAEYBMwAGcBcAGYBAYAF4CPgAEIAdgEjMBqV0h1p6hBYKq7i1oADt7DJhAEyggkgSBQg8Ak4ARqtX9U6rnHfsxyynumX3nhAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-diamond1.png',
            subLevels: [
                { level: 'SVIP 01', xp: '9217000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 02', xp: '10625000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 03', xp: '12033000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 04', xp: '13441000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 05', xp: '14849000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 06', xp: '16257000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 07', xp: '17665000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 08', xp: '19073000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 09', xp: '20481000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 10', xp: '21889000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 11', xp: '23297000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 12', xp: '24705000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 13', xp: '26113000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 14', xp: '27521000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 15', xp: '38913000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  }
            ]
        },
        {
            title: 'Diamond II',
            range: 'SVIP 16-37',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAFVBMVEUAAAD1UKryUKr0UKn2UKjzUKr0UKkKyqb7AAAABnRSTlMAnF7UJO8aXzayAAAAdUlEQVQ4y6XUQQ0AMQhE0ZHAOmhWQSVUxBqgUPxLWAf9B+b8AiEQhHnvGap7ksAkQC1cAD4CBiAEYBMwAGcBcAGYBAYAF4CPgAEIAdgEjMBqV0h1p6hBYKq7i1oADt7DJhAEyggkgSBQg8Ak4ARqtX9U6rnHfsxyynumX3nhAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-diamond2.png',
            subLevels: [
                { level: 'SVIP 16', xp: '41985000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 17', xp: '44057000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 18', xp: '74777000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 19', xp: '105497000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 20', xp: '136217000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 21', xp: '166937000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 22', xp: '197657000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 23', xp: '228377000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 24', xp: '259097000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 25', xp: '289817000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 26', xp: '320537000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 27', xp: '351257000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 28', xp: '381977000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 29', xp: '412697000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 30', xp: '443417000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 31', xp: '474137000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 32', xp: '504857000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 33', xp: '535577000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 34', xp: '566297000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 35', xp: '597017000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 36', xp: '627737000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  },
                { level: 'SVIP 37', xp: '335873000', img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png'  }
            ]
        },
        {
            title: 'Diamond III',
            range: 'SVIP 38-75',
            flagImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAqBAMAAADxKEDRAAAAFVBMVEUAAAD1UKryUKr0UKn2UKjzUKr0UKkKyqb7AAAABnRSTlMAnF7UJO8aXzayAAAAdUlEQVQ4y6XUQQ0AMQhE0ZHAOmhWQSVUxBqgUPxLWAf9B+b8AiEQhHnvGap7ksAkQC1cAD4CBiAEYBMwAGcBcAGYBAYAF4CPgAEIAdgEjMBqV0h1p6hBYKq7i1oADt7DJhAEyggkgSBQg8Ak4ARqtX9U6rnHfsxyynumX3nhAAAAAElFTkSuQmCC',
            badgeImage: '/assets/vip/badge-diamond3.png',
            subLevels: [
                { level: 'SVIP 38', xp: '366593000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 39', xp: '397313000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 40', xp: '428033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 41', xp: '735233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 42', xp: '1042433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 43', xp: '1349633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 44', xp: '1656833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 45', xp: '1964033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 46', xp: '2271233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 47', xp: '2578433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 48', xp: '2885633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 49', xp: '3192833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 50', xp: '3500033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 51', xp: '3807233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 52', xp: '4114433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 53', xp: '4421633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 54', xp: '4728833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 55', xp: '5036033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 56', xp: '5343233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 57', xp: '5650433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 58', xp: '5957633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 59', xp: '6264833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 60', xp: '6572033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 61', xp: '6879233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 62', xp: '7186433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 63', xp: '7493633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 64', xp: '7800833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 65', xp: '8108033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 66', xp: '8415233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 67', xp: '8722433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 68', xp: '9029633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 69', xp: '9336833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 70', xp: '9644033000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 71', xp: '9951233000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 72', xp: '10258433000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 73', xp: '10565633000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 74', xp: '10872833000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' },
                { level: 'SVIP 75', xp: '12058625000' , img:'https://hash.game/modules/bonus2/assets/small-badge-diamond-cb6bd479.png' }
            ]
        }
    ]
    return (
        <AccordionModal>
            <Header>
                <BackButton onClick={() => setShowLevelSystem(!showLevelSystem)}>
                    <ChevronLeft size={24} />
                </BackButton>
                <HeaderTitle>VIP Level System</HeaderTitle>
            </Header>

            {levels.map((level) => (
                <AccordionItem key={level.title}>
                    <AccordionHeader onClick={() => setOpenLevel(openLevel === level.title ? '' : level.title)}>
                        <LevelInfo>
                            <LevelFlagImage src={level.flagImage} alt={`${level.title} flag`} />
                            <LevelTitle>{level.title}</LevelTitle>
                            <LevelRange>{level.range}</LevelRange>
                        </LevelInfo>
                        {openLevel === level.title ? <ChevronDown size={20} color="white" /> : <ChevronRight size={20} color="white" />}
                    </AccordionHeader>

                    <AccordionContent isOpen={openLevel === level.title}>
                        {level.subLevels.map((subLevel) => (
                            <LevelRow key={subLevel.level}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <LevelIcon src={subLevel.img} alt={`level${subLevel.img}`} />
                                    <span style={{ color: 'white' }}>{subLevel.level}</span>
                                </div>
                                <RequiredXP>{subLevel.xp}</RequiredXP>
                            </LevelRow>
                        ))}
                    </AccordionContent>
                </AccordionItem>
            ))}
        </AccordionModal>
    );
};

export default VIPLevelAccordion;