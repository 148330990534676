import { ArrowLeft, ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import StarIcon from './Stars';
import { useHistory } from 'react-router-dom';
import VIPLevelAccordion from './LevelsAccordion';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 12px;

  width: 50%;
  max-width: 544px;

  color: white;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1001;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);  // Added minimal drop shadow

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const VIPContainer1 = styled.div`
  background: linear-gradient(20deg, rgba(93, 129, 172, 0) 52.52%, #5D81AC 80.76%);
  padding: 18px 14px;
    background-color:rgb(35 38 38);
    background-image:linear-gradient(20deg, rgba(93, 129, 172, 0) 52.52%, #5D81AC 110.76%);
`;

const TopCard = styled.div`
  background-image: linear-gradient(to right, #1E2128, #2A2D36);
  background-image: url('https://hash.game/modules/bonus2/assets/bg-none-38f488cb.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopCardContainer = styled.div`
  background-image: linear-gradient(11deg, #32373800 51%, #5d81ac 106.98%);
  background-image: url('https://hash.game/modules/bonus2/assets/bg-none-38f488cb.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 10px 8px;  // Fixed the padding syntax (removed semicolon)
  margin-bottom: 20px;
  position: relative;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-bottom: 15px solid #323738;  // Added gray bottom border with 5px thickness
`;

const VIPContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 70%; // Ensure content doesn't overlap with image
`;

const VIPImage = styled.div`
  width: 140px;
  height: 140px;
  background-image: url('https://hash.game/assets/vip/badge-none.png'); // Replace with your image URL
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  align-self: center;
  margin-left: 16px;
  margin-top:-80px;
`;

const VIPInfo = styled.div`
  position: relative;
  z-index: 1;
`;

const VIPTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 8px;
  color: #fff;
  font-weight:bold;
`;

const XPText = styled.p`
  font-size: 16px;
  color: #FFFFFF;
  margin: 6px 0;
`;

const ProgressBar = styled.div`
  background: rgba(30, 33, 40, 0.8);
  height: 8px;
  border-radius: 4px;
  margin: 8px 0;
  position: relative;
`;

const Progress = styled.div`
  background: #4CAF50;
  height: 100%;
  border-radius: 4px;
  width: ${props => props.progress}%;
  transition: width 0.3s ease;
`;

const BonusTable = styled.div`
  margin-top: 24px;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TableTitle = styled.h3`
  font-size: 20px;
  color: #FFFFFF;
`;

const ArrowContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const Arrow = styled.button`
  background: #1E2128;
  border: none;
  color: white;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background: #2A2D36;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TableContainer = styled.div`
  display: flex;
  overflow: hidden;
  background: rgba(30, 33, 40, 0.3);
  border-radius: 8px;
  position: relative;
  align-items: stretch;
`;

const FixedColumn = styled.div`
  flex: 0 0 180px;
  background: rgba(30, 33, 40, 0.5);

  
  /* Ensure consistent height with scrollable columns */
  > div:first-child {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
  }
`;

const ScrollableColumns = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  transform: translateX(${props => props.offset}px);
`;

const Column = styled.div`
  flex: 0 0 110px;

  text-align: center;
 
  
  &:last-child {
    border-right: none;
  }
`;

const ColumnHeader = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #292D2F;
  margin-bottom: 16px;
  
  &:nth-child(2) {
    background-color: orange;
  }
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const BonusRow = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const BonusRow1 = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  padding-left: 8px;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const Arrow1 = styled.button`
  background: #434B4C;
  border: none;
  color: white;
  padding: 2px;
  border-radius: 20%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  position: absolute;
  top: 5%;
  transform: translateY(-50%);
  z-index: 2;
  ${props => props.direction === 'left' ? 'left:300px;' : 'right: 10px;'}

  &:hover {
    background: #2A2D36;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;



// Updated levelStars object
const levelStars = {
    Bronze: {
        'Level-up Bonus': 'bronze',
        'Raining': 'bronze',
        'Coin Drops': 'bronze',
        'Private Chat': null,
        'Tips': null,
        'VIP Spin': null,
        'Recharge': null,
        'Weekly Cashback': null
    },
    Silver: {
        'Level-up Bonus': 'silver',
        'Raining': 'silver',
        'Coin Drops': 'silver',
        'Private Chat': 'silver',
        'Tips': null,
        'VIP Spin': null,
        'Recharge': null,
        'Weekly Cashback': null
    },
    Gold: {
        'Level-up Bonus': 'gold',
        'Raining': 'gold',
        'Coin Drops': 'gold',
        'Private Chat': 'gold',
        'Tips': 'gold',
        'VIP Spin': 'gold',
        'Recharge': null,
        'Weekly Cashback': null
    },
    'Platinum I': {
        'Level-up Bonus': 'platinum',
        'Raining': 'platinum',
        'Coin Drops': 'platinum',
        'Private Chat': 'platinum',
        'Tips': 'platinum',
        'VIP Spin': 'platinum',
        'Recharge': 'platinum',
        'Weekly Cashback': null
    },
    'Diamond I': {
        'Level-up Bonus': 'diamond',
        'Raining': 'diamond',
        'Coin Drops': 'diamond',
        'Private Chat': 'diamond',
        'Tips': 'diamond',
        'VIP Spin': 'diamond',
        'Recharge': 'diamond',
        'Weekly Cashback': 'diamond'
    }
};


const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom:10px;
`;

const BackButton = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: white;
`;
const LevelButton = styled.div`
  background-color:#323738;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;

  padding:8px;
  border-radius:12px;
`;

const TextLevel = styled.div`
  color:#23EE88;
  font-size:16px;
`;

const mockData = {
    levels: ['Bronze', 'Silver', 'Gold', 'Platinum I', 'Platinum II'],
    bonusTypes: [
        { name: 'Level-up Bonus', icon: '' },
        { name: 'Raining', icon: '' },
        { name: 'Coin Drops', icon: '滋' },
        { name: 'Private Chat', icon: '' },
        { name: 'Tips', icon: '' }
    ]
};

const VIPClubModal = ({ onClose }) => {
    const [currentPage, setCurrentPage] = useState(0);

    const levels = [
        { name: 'Bronze', icon: 'https://hash.game/assets/vip/badge-bronze.png' },
        { name: 'Silver', icon: 'https://hash.game/assets/vip/badge-silver.png' },
        { name: 'Gold', icon: 'https://hash.game/assets/vip/badge-gold.png' },
        { name: 'Platinum I', icon: 'https://hash.game/assets/vip/badge-platinum.png' },
        { name: 'Diamond I', icon: 'https://hash.game/assets/vip/badge-diamond.png' },
        { name: 'Diamond II', icon: 'https://hash.game/assets/vip/badge-diamond.png' },
        { name: 'Diamond III', icon: 'https://hash.game/assets/vip/badge-diamond.png' }
    ];

    const bonusTypes = [
        { name: 'Level-up Bonus', icon: 'https://hash.game/modules/bonus2/assets/icon-bonus-32b85a57.png' },
        { name: 'Raining', icon: 'https://hash.game/modules/bonus2/assets/icon-raining-80cb23bc.png' },
        { name: 'Coin Drops', icon: 'https://hash.game/modules/bonus2/assets/icon-coin-drop-6d4014d9.png' },
        { name: 'Private Chat', icon: 'https://hash.game/modules/bonus2/assets/icon-host-fa29e741.png' },
        { name: 'Tips', icon: 'https://hash.game/modules/bonus2/assets/icon-tips-a648ee03.png' },
        { name: 'VIP Spin', icon: 'https://hash.game/modules/bonus2/assets/icon-spin-80009429.png' },
        { name: 'Recharge', icon: 'https://hash.game/modules/bonus2/assets/icon-recharge-7f0b6d68.png' },
        { name: 'Weekly Cashback', icon: 'https://hash.game/modules/bonus2/assets/icon-cashback-weekly-1fa58d98.png' }
    ];

    const maxPage = Math.ceil((levels.length - 1) / 2);

    const handleNext = () => {
        setCurrentPage(prev => Math.min(prev + 1, maxPage - 1));
    };

    const handlePrev = () => {
        setCurrentPage(prev => Math.max(prev - 1, 0));
    };

    const visibleLevels = levels.slice(1).slice(currentPage * 2, (currentPage * 2) + 2);

    const getStarImage = (level, bonusType) => {
        const levelConfig = levelStars[level] || {};
        const starColor = levelConfig[bonusType];

        if (!starColor) return '--';

        return <StarIcon color={starColor} />;
    };

    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };
    const [showLevelSystem, setShowLevelSystem] = useState(false);

    return (
        <Overlay>
            <Modal>

                {
                    !showLevelSystem ? (<>
                        <VIPContainer1>

                            <HeaderContainer>
                                <BackButton onClick={handleBack}>
                                    <ChevronLeft size={24} color="white" />
                                </BackButton>
                                <Title>VIP Bonus</Title>
                            </HeaderContainer>

                            <TopCardContainer>
                                <VIPContentContainer>
                                    <VIPInfo>
                                        <VIPTitle>VIP 0</VIPTitle>
                                        <XPText>0 XP / 1 XP</XPText>
                                        <ProgressBar>
                                            <Progress progress={0} />
                                        </ProgressBar>
                                        <XPText>1XP until VIP 1</XPText>
                                    </VIPInfo>
                                </VIPContentContainer>
                                <VIPImage />
                            </TopCardContainer>
                            <LevelButton onClick={() => setShowLevelSystem(true)}>
                                <TextLevel>
                                    Learn More VIP Level Up System
                                </TextLevel>
                            </LevelButton>
                            <BonusTable>
                                <TableHeader>
                                    <TableTitle>Exclusive VIP Bonus</TableTitle>
                                </TableHeader>

                                <TableContainer>
                                    <FixedColumn>
                                        <ColumnHeader><div style={{ color: "#839093", marginTop: "24px", marginLeft: "-20px", fontWeight: 'bold' }}>
                                            Bonus Type</div></ColumnHeader>
                                        {bonusTypes.map(bonus => (
                                            <BonusRow1 key={bonus.name}>
                                                <img src={bonus.icon} alt={bonus.name} />
                                                <span>{bonus.name}</span>
                                            </BonusRow1>
                                        ))}
                                    </FixedColumn>

                                    <Arrow1
                                        direction="left"
                                        onClick={handlePrev}
                                        disabled={currentPage === 0}
                                    >
                                        <ChevronLeft size={15} />
                                    </Arrow1>

                                    <div style={{ display: "flex", gap: '4px' }}>
                                        <ScrollableColumns offset={0}>
                                            <Column>
                                                <ColumnHeader>
                                                    <img src={levels[0].icon} alt={levels[0].name} />
                                                    {levels[0].name}
                                                </ColumnHeader>
                                                {bonusTypes.map(bonus => (
                                                    <BonusRow key={`bronze-${bonus.name}`}>
                                                        {getStarImage('Bronze', bonus.name)}
                                                    </BonusRow>
                                                ))}
                                            </Column>

                                            {visibleLevels.map(level => (
                                                <Column key={level.name}>
                                                    <ColumnHeader>
                                                        <img src={level.icon} alt={level.name} />
                                                        {level.name}
                                                    </ColumnHeader>
                                                    {bonusTypes.map(bonus => (
                                                        <BonusRow key={`${level.name}-${bonus.name}`}>
                                                            {getStarImage(level.name, bonus.name)}
                                                        </BonusRow>
                                                    ))}
                                                </Column>
                                            ))}
                                        </ScrollableColumns>
                                        <Arrow1
                                            direction="right"
                                            onClick={handleNext}
                                            disabled={currentPage === maxPage - 1}
                                        >
                                            <ChevronRight size={15} />
                                        </Arrow1>
                                    </div>




                                </TableContainer>
                            </BonusTable>
                        </VIPContainer1>
                    </>) : (

                        <VIPLevelAccordion setShowLevelSystem={setShowLevelSystem} showLevelSystem={showLevelSystem} />
                    )
                }

            </Modal>
        </Overlay>
    );
};

export default VIPClubModal;