import React from "react";

const CommissionHistory = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <img
        src="https://hash.game/assets/common/empty.png"
        alt="crying-lion"
        width={200}
        height={200}
        style={{
          borderRadius: "10px",
          filter: "grayscale(1) brightness(0.6)",
        }}
      />

      <div style={{ marginTop: "20px", textAlign: "center", opacity: "0.6" }}>
        No rewards yet. <br /> invite friends to join you now!
      </div>
    </div>
  );
};

export default CommissionHistory;
