import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Row, Col, Card } from "react-bootstrap";
import Bet from "./Bet";
import C from '../../../Constant'
import Queue from "../../Components/Game/Queue";
import { wait, isMobile, Event, encode, getUID, decode } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Engine from "./Engine";
import Loading from "../Loading";
import BetStatsPopup from '../../Components/Graphpop';
import styled from 'styled-components';
import socket from '../../../Socket';
import storage from "../../../Storage"

const OpenGraphButton = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  background-color: #222328;
  border-radius: 50%;
`;

const MainWrapper = styled.div`
  display: flex;
  width: 100%;

  /* Mobile layout (default) */
  flex-direction: column;

  /* Desktop layout (768px and above) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Index = () => {
  const [engine] = useState(new Engine());
  const [margin, setMargin] = useState('mt-3');
  const [padding, setPadding] = useState('p-1');
  const [showPopup, setShowPopup] = useState(false);
  const [wagered, setWagered] = useState("0.00000000");
  const [profit, setProfit] = useState("0.00000000");
  const [gameData, setGameData] = useState(null);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [col, setCol] = useState('col-xl-9');
  const [userData, setUserData] = useState(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    engine.started = true;
    setupEngineListeners();

    wait(600).then(handleResize);
    window.addEventListener('resize', handleResize);

    socket.on(C.USER_INFO, handleUserInfo);
    requestUserInfo();

    return () => {
      window.removeEventListener('resize', handleResize);
      engine.started = false;
      engine.trigger = null;
      engine.off();
      socket.off(C.USER_INFO, handleUserInfo);
    };
  }, []);

  const setupEngineListeners = () => {
    engine.on(C.PLAY_CLASSIC_DICE, handlePlayEvent);
    engine.on(C.BUSTED_CLASSIC_DICE, handleBustedEvent);
    engine.on(C.ERROR_CLASSIC_DICE, handleErrorEvent);
  };

  const handlePlayEvent = (data) => {
    if (mounted) {
      console.log('Play event:', data);
      setGameData(data);
      setIsPlaying(true);

      // Update wagered and profit when game data is received
      if (data) {
        setWagered(prevWagered => {
          const currentWagered = parseFloat(prevWagered) || 0;
          const newWager = parseFloat(data.bet) || 0;
          return (currentWagered + newWager).toFixed(8);
        });
      }
    }
  };

  const handleBustedEvent = (data) => {
    if (mounted) {
      console.log('Busted event:', data);
      setGameData(data);
      setIsPlaying(false);

      // Update profit when game ends
      if (data && data.profit) {
        setProfit(prevProfit => {
          const currentProfit = parseFloat(prevProfit) || 0;
          const newProfit = parseFloat(data.profit) || 0;
          return (currentProfit + newProfit).toFixed(8);
        });
      }
    }
  };

  const handleErrorEvent = (error) => {
    if (mounted) {
      console.error('Error event:', error);
      setError(error);
      setIsPlaying(false);
    }
  };

  const handleResize = () => {
    const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    if (clientWidth < 1540) {
      setCol('col-xl-12');
      Event.emit('hide_games');
    } else {
      setCol('col-xl-9');
      Event.emit('show_min_games');
    }
    if (isMobile()) {
      setPadding('p-0');
      setMargin('mt-1');
    }
  };

  const togglePopup = () => {
    // Ensure we have valid values before showing the popup
    const validWagered = wagered || "0.00000000";
    const validProfit = profit || "0.00000000";

    setWagered(validWagered);
    setProfit(validProfit);
    setShowPopup(prev => !prev);
  };

  const handleUserInfo = (data) => {
    const decodedData = decode(data);
    if (decodedData.status) {
      console.log("User data received:", decodedData);
      setUserData(decodedData);
    }
  };

  const requestUserInfo = () => {
    socket.emit(C.USER_INFO, encode({
      id: getUID,
      coin: storage.getKey('coin') || '',
      rate: null,
      game: 'all',
      first: true
    }));
  };

  const help = 'Crypto Classic Dice is a probability game established by blockchain hash value calculation and algorithm, provides more fun with bet and prediction, in which the closer the number rolled by players to the random number, the higher the probability winning.';

  if (!mounted) return <Loading />;


  console.log("game data", gameData)

  return (
    <>
      <Helmet>
        <title>Classic Dice - Crypto Casino Games</title>
        <meta name="keywords" content="Crypto Classic Dice Game, Online Classic Dice Game, Bitcoin Classic Dice Game, Blockchain Classic Dice Game, Best Crypto Classic Dice Game, PlayCrash.com Classic Dice" />
        <meta name="description" content={help} />
        <meta name="og:title" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <meta name="og:description" content="Play Online Classic Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
        <link rel="canonical" href="/classic-dice" />
      </Helmet>
      <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%', background: 'transparent' }}>
        {showPopup && (
          <BetStatsPopup
            onClose={togglePopup}
            engine={engine}
          />
        )}
        <Row className="animated fadeIn">
          <div className="d-none">Crypto Classic Dice Description: {help}</div>
          <Col sm={12} className={`m-auto ${col} ${padding}`}>
            {/* <Card className="mb-0">
              <Card.Body className="px-2 py-3">
                <Row>
                  <Col md={6} className="col-6 text-left">
                    <BankRoll game={'classic_dice'} />
                  </Col>
                  <Col md={6} className="col-6 text-right">
                    <div style={{ display: 'flex' }}>
                      <TopBarGames image={"/assets/images/two.dice.png"} help={help} />
                      <div onClick={togglePopup} style={{ marginLeft:'3px', alignItems:'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                        <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                      </div>

                    </div>

                  </Col>

                </Row>
              </Card.Body>
            </Card> */}
            {/* <Card className="mb-0">
              <Card.Body className="bg-dice-options p-1 animated fadeIn" id="roll-panel"> */}

            <MainWrapper>
              <Bet engine={engine} />
              <div style={{ flex: '1', height: "100vh" }}>
                <Game engine={engine} />
              </div>
            </MainWrapper>


            {/* </Card.Body>
            </Card> */}
            <Card className="mb-0">
              <Card.Body className={padding}>
                <Row>
                  <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    {/* <Col sm={12} md={10} className="m-auto">
                      <Bet engine={engine} />
                    </Col> */}
                    {/* <OpenGraphButton onClick={togglePopup}>
                      <img style={{ height: '20px', width: '20px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
                    </OpenGraphButton> */}
                  </div>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-5">
              <Card.Body className="p-1">
                <Row>
                  <Col sm={12} md={12} className="m-auto px-1">
                    <Queue game={'classic_dice'} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Index;