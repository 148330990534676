import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Heart, PencilIcon, UserRound, Wallet2, X } from 'lucide-react';
import { Dropdown, Modal, Button } from "react-bootstrap";
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import socket from '../../../../Socket';
import C from "../../../../Constant";
import { __, addNewStyle, CRISTP_ID, decode, defaultAvatar, encode, getUID, toUSD, wait } from "../../../../Helper";
import storage from '../../../../Storage';
import { getVipLevelDetails } from '../../VipClub/calculateLevel';


const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuText = styled.div`
  color: #BCC7CB;
  font-size: 16px;
`;


const ProfileButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
`;

const ProfileImage = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: contain;
`;

const MenuPopover = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #292D2E;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  padding: 8px;
  width: 250px;
  z-index: 100;

  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    right: 0;
    top: 10%;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 12px 12px 0 0;
    max-height: 100vh;
    overflow-y: auto;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 7px;
   img {
    transition: filter 0.2s ease-in-out;
  }
`;
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.58rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #323738;
    
    // If you need the text and icon to change color on hover
    ${MenuText} {
      color: white;  // Or whatever dark color you prefer
    }
    
    ${IconWrapper} {
      img {
        filter: brightness(1);  // White icon for dark background
      // OR you can use
      // filter: invert(1);  // This also makes icons white
      }
    }
  }
`;


const StyledImg = styled.img`
  height: 18px;
  width: 18px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg) brightness(94%) contrast(87%);
`;

const StyledIcon = styled.img`
  height: 18px;
  width: 18px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg) brightness(94%) contrast(87%);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
`;

const StyledNew = styled.div`
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181B;
    border-radius: 15px;
    border: none;
    color: white;
  }
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 90%;
      width: 400px;
    }
    @media (min-width: 768px) {
      max-width: 85%;
      width: 500px;
    }
    @media (min-width: 992px) {
      max-width: 80%;
      width: 600px;
    }
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
  background-color: #17181B;
`;

const StatBox = styled.div`
  background-color: #1B1C1F;
  padding: 15px;
  border-radius: 15px;
  text-align: center;
  
  .stat-label {
    color: #676D76;
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .stat-value {
    font-size: 14px;
    font-weight: bold;
  }
`;
const MobileCloseButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255,255,255,0.1);
    border: none;
    border-radius: 10%;
    padding: 8px;
    z-index: 101;
  }
`;



const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledButtonNew = styled.button`
  background-color: #56CF16;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #56CF16;
    transform: scale(1.05);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family:normal;
`;
const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282C;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;


const Pencil = styled.div`
display:flex;
justify-content:center
background-color:#1C1D21;
padding: 0.75rem;
border-radius:50%;
`;


const UserContainer = styled.div`
display:flex;
justify-content:space-between;
gap:5px;
padding:5px;
`;


const ProfileMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showEditUi, setShowEditUi] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const history = useHistory();
    const progressAnimationRef = useRef(null);
    const currentName = storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest';

    // Convert all class state to useState hooks
    const [level, setLevel] = useState(1);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [displayUid] = useState(getUID);
    const [name, setName] = useState(currentName);
    const [avatar] = useState(defaultAvatar);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupUserProfile, setShowPopupUserProfile] = useState(false);
    const [showPopupUserProfileStatistics, setShowPopupUserProfileStatistics] = useState(false);
    const [showWalletModal, setShowWalletModal] = useState(false);
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const [activeSection, setActiveSection] = useState('deposit');
    const [haveData, setHaveData] = useState('');
    const [data, setData] = useState([]);
    const [currentLevel, setCurrentLevel] = useState('vip 0');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [nextLevel, setNextLevel] = useState('vip 1');
    const [progress, setProgress] = useState(0);
    const [xpNeeded, setXpNeeded] = useState(1);
    const [currentXP, setCurrentXP] = useState(0);
    const [vipDetails, setVipDetails] = useState(null);
    const [wageredAmount, setWageredAmount] = useState(null);
    const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);
    const [newUsername, setNewUsername] = useState(currentName);
    const [updateError, setUpdateError] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleUserInfoClick = () => {
        // Fetch user info when opening modal
        socket.emit(C.USER_INFO, encode({
            id: getUID,
            coin: storage.getKey('coin') ? storage.getKey('coin') : '',
            rate: null,
            game: 'all',
            first: true
        }));
        setShowUserModal(true);
        setIsOpen(false);
    };
    
    const ask = () => {
        Swal.fire({
            title: 'Are you sure to logout?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                socket.emit(C.LOGOUT_USER);
                localStorage.clear();
                Cookies.remove('session');
                Cookies.remove('auth');
                Cookies.remove('uid');
                window.location.replace('./');
            }
        });
    };
    const handleToggle = () => {
        setIsOpenMenu(prev => !prev);
    };

    const handleUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const updateUsername = async () => {
        if (!newUsername.trim()) {
            setUpdateError('Username cannot be empty');
            return;
        }

        setIsUpdatingUsername(true);
        setUpdateError(null);

        try {
            const response = await fetch('https://api.99exchange.red/editProfile', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: displayUid,
                    username: newUsername.trim()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update username');
            }

            const data = await response.json();
            console.log("data is", data);

            // Update local storage and state with new username
            storage.setKey('name', newUsername);
            setName(newUsername);
            setShowEditUi(false);

            // Refresh user info
            socket.emit(C.USER_INFO, encode({
                id: getUID,
                coin: storage.getKey('coin') ? storage.getKey('coin') : '',
                rate: null,
                game: 'all',
                first: true
            }));

        } catch (error) {
            setUpdateError('Failed to update username. Please try again.');
        } finally {
            setIsUpdatingUsername(false);
        }
    };

    const getUserInfo = (data) => {
        if (data.status) {
            console.log("data received", data);
            setHaveData('ok');
            setData(data);

            let amount = parseFloat(data?.profit).toFixed(4);
            if (amount) {
                setWageredAmount(amount);
                const vipInfo = getVipLevelDetails(amount);
                setVipDetails(vipInfo);
                animateProgress(parseFloat(vipInfo.completionPercentage));
            }
        } else {
            setHaveData('notFound');
        }
    };

    const animateProgress = (targetProgress) => {
        let currentProgress = 0;
        const duration = 1000; // 1 second animation
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);

            currentProgress = progress * targetProgress;
            setProgress(currentProgress);

            if (progress < 1) {
                progressAnimationRef.current = requestAnimationFrame(animate);
            }
        };

        progressAnimationRef.current = requestAnimationFrame(animate);
    };

    const handleTogglePopup = () => {
        console.log("get ui", getUID);
        socket.emit(C.USER_INFO, encode({
            id: getUID,
            coin: storage.getKey('coin') ? storage.getKey('coin') : '',
            rate: null,
            game: 'all',
            first: true
        }));
        setShowPopup(prev => !prev);
        setShowEditUi(false);
    };

    const handleTogglePopupProfile = () => {
        console.log("profile");
        setShowPopupUserProfile(prev => !prev);
    };

    const handleTogglePopupProfileStatistics = () => {
        setShowPopupUserProfileStatistics(prev => !prev);
    };

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    const support = () => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = CRISTP_ID;

        if (!loaded) {
            console.log("support");
            setLoaded(true);
            const script = document.createElement('script');
            script.src = "https://client.crisp.chat/l.js";
            script.id = "chtt";
            script.async = true;
            document.head.appendChild(script);
        }

        addNewStyle("#crisp-chatbox {display:block !important;}");
        addNewStyle(
            ".crisp-client .cc-kv6t .cc-1xry .cc-ge4v .cc-qqzz .cc-5b24 .cc-19cg {display:none !important;}"
        );

        wait(2000).then(() => {
            const close = document.querySelector(".cc-wdhl");
            if (close === null) return;
            close.addEventListener("click", function (event) {
                addNewStyle("#crisp-chatbox {display:none !important;}");
                document.getElementById("chtt").src = "";
            });
        });
    };

    // ComponentDidMount equivalent
    useLayoutEffect(() => {
        socket.on(C.USER_INFO, data => getUserInfo(decode(data)));
    
        // ComponentWillUnmount equivalent
        return () => {
            if (progressAnimationRef.current) {
                cancelAnimationFrame(progressAnimationRef.current);
            }
            socket.off(C.USER_INFO);
        };
    }, []);

    const handleStatisticsClick = () => {
        setIsOpen(false);
      };
      
      const handleTransactionsClick = () => {
        history.push("/transactions");
        setIsOpen(false);
      };
      
      const handleSupportClick = () => {
        support();
        setIsOpen(false);
      };
      
      const handleAffiliateClick = () => {
        history.push("/affiliate");
        setIsOpen(false);
      };
      
      const handleWalletClick = () => {
        history.push("/wallet");
        setIsOpen(false);
      };
      
      const handleSwapClick = () => {
        history.push("/swap");
        setIsOpen(false);
      };
      
      const handleVaultProClick = () => {
        history.push("/vault-pro");
        setIsOpen(false);
      };
      
      const handleRakebackClick = () => {
        history.push("/rakeback");
        setIsOpen(false);
      };
      
      const handleVipClubClick = () => {
        history.push("/vip-club");
        setIsOpen(false);
      };
      
      const handleGlobalSettingsClick = () => {
        history.push("/global-settings");
        setIsOpen(false);
      };
      
      const handleLogoutClick = () => {
        ask();
        setIsOpen(false);
      };
    


    return (
        <Container>
            <ProfileButton
                ref={buttonRef}
                onClick={() => setIsOpen(!isOpen)}
            >
                <ProfileImage
                    src="//img2.distributedresourcestorage.com/avatar/57268122/s?t=0"
                    alt="Profile"
                />
            </ProfileButton>

            {isOpen && (
                <MenuPopover ref={menuRef}>
                     <MobileCloseButton 
                        onClick={() => setIsOpen(false)}
                    >
                        <X size={24} color="#BCC7CB" />
                    </MobileCloseButton>
                    <MenuItem onClick={handleUserInfoClick}>
                        <IconWrapper>
                            <UserRound color="#BCC7CB" size={18} />
                        </IconWrapper>
                        <MenuText>User Information</MenuText>
                    </MenuItem>
                    {/* <MenuItem onClick={handleStatisticsClick} >
                        <IconWrapper >
                            <StyledIcon src="/assets/images/nanoicons/stats.png" alt="Statistics" />
                        </IconWrapper>
                        <MenuText>Statistics</MenuText>
                    </MenuItem> */}
                    <MenuItem  onClick={handleTransactionsClick} >
                        <IconWrapper>
                            <StyledIcon src="/assets/images/nanoicons/transactions.png" alt="Transactions" />
                        </IconWrapper>
                        <MenuText>Transactions</MenuText>
                    </MenuItem>
                    <MenuItem onClick={support}>
                        <IconWrapper>
                            <StyledIcon src="/assets/images/nanoicons/liveSupport.png" alt="Support" />
                        </IconWrapper>
                        <MenuText>Live Support</MenuText>
                    </MenuItem>
                    <StyledLink onClick={handleAffiliateClick} to="/affiliate">
                        <MenuItem>
                            <IconWrapper>
                                <StyledIcon src="/assets/images/nanoicons/affiliates.png" alt="Affiliate" />
                            </IconWrapper>
                            <MenuText>Affiliate</MenuText>
                        </MenuItem>
                    </StyledLink>
                    <MenuItem onClick={handleWalletClick}>
                        <IconWrapper>
                            <Wallet2 color="#BCC7CB" size={18} />
                        </IconWrapper>
                        <MenuText>Wallet</MenuText>
                    </MenuItem>
                    <MenuItem  onClick={handleSwapClick}>
                        <IconWrapper>
                            <StyledIcon src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
                        </IconWrapper>
                        <MenuText>Swap</MenuText>
                    </MenuItem>
                    <MenuItem  onClick={handleVaultProClick}>
                        <IconWrapper>
                            <StyledIcon src="/assets/images/nanoicons/vault.png" alt="Vault" />
                        </IconWrapper>
                        <MenuText>Vault Pro</MenuText>
                    </MenuItem>
                    <StyledLink to="/rakeback">
                        <MenuItem onClick={handleRakebackClick}>
                            <IconWrapper>
                                <StyledIcon src="/assets/images/nanoicons/rewards.png" alt="Rakeback" />
                            </IconWrapper>
                            <MenuText>Rakeback</MenuText>
                        </MenuItem>
                    </StyledLink>
                    <StyledLink  to="/vip-club">
                        <MenuItem onClick={handleVipClubClick}>
                            <IconWrapper>
                                <StyledIcon src="/assets/images/nanoicons/vipclub.png" alt="VIP" />
                            </IconWrapper>
                            <MenuText>VIP club</MenuText>
                        </MenuItem>
                    </StyledLink>
                    <StyledLink to="/global-settings">
                        <MenuItem onClick={handleGlobalSettingsClick} >
                            <IconWrapper>
                                <StyledIcon src="/assets/images/normal-seetings.png" alt="VIP" />
                            </IconWrapper>
                            <MenuText>Global Settings</MenuText>
                        </MenuItem>
                    </StyledLink>
                    <StyledNew onClick={ask}>
                        <MenuItem>
                            <IconWrapper>
                                <StyledImg src='/assets/images/nanoicons/logout.png' alt="Logout" />
                            </IconWrapper>
                            <MenuText>Logout</MenuText>
                        </MenuItem>
                    </StyledNew>
                </MenuPopover>
            )}

            <StyledModal show={showUserModal} onHide={() => setShowUserModal(false)} centered>
                <ModalHeader>
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>User Profile</div>
                    <button className="close-button" onClick={() => {
                        setShowUserModal(false)
                        setShowEditUi(false)
                    }}>
                        <X size={24} />
                    </button>
                </ModalHeader>
                <ModalBody>

                    {
                        showEditUi ? (
                            <div style={{ width: "100%", height: '50vh' }}>
                                <div>
                                    <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img style={{ width: '100px', height: '100px', borderRadius: '50%' }} src="https://img2.nanogames.io/avatar/589929/s?t=1727260678731" />


                                    </div>


                                </div>
                                <InputContainer>
                                    <Label>Username</Label>
                                    <Input
                                        type="text"
                                        value={newUsername}
                                        onChange={handleUsernameChange}
                                        placeholder="Enter new username"
                                        disabled={isUpdatingUsername}
                                    />
                                </InputContainer>
                                <Label>Do not use special punctuation, otherwise your account may not be supported.</Label>
                                {/* {updateError && <Label style={{ color: 'red' }}>{updateError}</Label>} */}

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <StyledButtonNew
                                        type="button"
                                        onClick={updateUsername}
                                        disabled={isUpdatingUsername}
                                    >
                                        {isUpdatingUsername ? 'Updating...' : 'Modify'}
                                    </StyledButtonNew>
                                </div>
                            </div>) : (
                            <div style={{ padding: '20px 0' }}>
                                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                                    <img
                                        src="//img2.distributedresourcestorage.com/avatar/57268122/s?t=0"
                                        style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }}
                                        alt="Profile"
                                    />
                                    <h3>{name}</h3>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', alignItems: "center" }}>
                                        <Heart size={16} color="#ff6b6b" />
                                        <span>Level {data.level}</span>
                                        <Pencil onClick={() => setShowEditUi(true)}>
                                            <PencilIcon size={10} />
                                        </Pencil>
                                    </div>

                                </div>
                                <div style={{ backgroundColor: '#1E2024', padding: '20px', borderRadius: '15px' }}>
                                    <h4 style={{ marginBottom: '15px' }}>Medals {data.medal}</h4>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
                                        <img src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                        <img src="https://static.nanogames.io/assets/achieve_3.bf42dfa3.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                        <img src="https://static.nanogames.io/assets/achieve_4.c845bc7e.png" style={{ width: '44px', height: '63px' }} alt="Medal" />
                                    </div>
                                </div>

                                <div style={{ backgroundColor: '#1E2024', padding: '20px', borderRadius: '15px', marginTop: '20px' }}>
                                    <h4 style={{ marginBottom: '15px' }}>Statistics</h4>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }}>
                                        <StatBox>
                                            <div className="stat-label">Total Wins</div>
                                            <div className="stat-value">{data.wined}</div>
                                        </StatBox>
                                        <StatBox>
                                            <div className="stat-label">Total Bets</div>
                                            <div className="stat-value">{data?.played}</div>
                                        </StatBox>
                                        <StatBox>
                                            <div className="stat-label">Total Wagered</div>
                                            <div className="stat-value">{data?.profit}</div>
                                        </StatBox>
                                    </div>
                                </div>


                            </div>
                        )
                    }

                </ModalBody>
            </StyledModal>
        </Container>
    );
};

export default ProfileMenu;